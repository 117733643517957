import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import ChatIcon from '@material-ui/icons/Chat';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import HouseIcon from '@material-ui/icons/House';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PhoneIcon from '@material-ui/icons/Phone';
import PlaceIcon from '@material-ui/icons/Place';
import PublicIcon from '@material-ui/icons/Public';
import RoomIcon from '@material-ui/icons/Room';
import formatTz from 'date-fns-tz/format';
import format from 'date-fns/format';
import isFuture from 'date-fns/isFuture';
import isToday from 'date-fns/isToday';
import L from 'leaflet';
import React from 'react';
import EventCard from '../components/EventCard';
import { URI_PARAM_PAN } from '../constants/uri';
import urlExtractor from '../utils/urlExtractor';

function formattedRange(timeStart, timeEnd, timezone) {
  const useTz = !!timezone;
  if (timeStart && timeEnd) {
    const tz = useTz
      ? {
          timezone: timezone,
        }
      : null;
    return `${format(timeStart, 'eee, MMM d', tz)}, ${format(
      timeStart,
      'p',
      tz
    )}-${(useTz ? formatTz : format)(timeEnd, useTz ? 'p z' : 'p', tz)}`;
  }
  return null;
}

export function convertToDate(x) {
  return new Date(x * 1000);
}

export const TYPE_TRAVIS = 't';
export const TYPE_HOWARD = 'h';

export const TYPE_ICON_MAP = {
  HOUSE_PARTY: HouseIcon,
  COMMUNITY: PublicIcon,
  PHONE_BANK: PhoneIcon,
  MEETING: AssignmentIcon,
  CANVASS: DirectionsWalkIcon,
  MEET_GREET: EmojiPeopleIcon,
  FUNDRAISER: MonetizationOnIcon,
  OTHER: RoomIcon,
  DEBATE_WATCH_PARTY: LiveTvIcon,
  COMMUNITY_CANVASS: PlaceIcon,
  RALLY: AssistantPhotoIcon,
  TEXT_BANK: ChatIcon,
  TOWN_HALL: AccountBalanceIcon,
  SIGNATURE_GATHERING: BorderColorIcon,
  OFFICE_OPENING: NewReleasesIcon,
  TRAINING: LocalLibraryIcon,
};

export const TYPE_TITLE_MAP = {
  HOUSE_PARTY: 'House Party',
  COMMUNITY: 'Community',
  PHONE_BANK: 'Phonebank',
  MEETING: 'Meeting',
  CANVASS: 'Canvass',
  MEET_GREET: 'Meet & Greet',
  FUNDRAISER: 'Fundraiser',
  OTHER: 'Other',
  DEBATE_WATCH_PARTY: 'Debate Watch',
  COMMUNITY_CANVASS: 'Community Canvass',
  RALLY: 'Rally',
  TEXT_BANK: 'Textbank',
  TOWN_HALL: 'Town Hall',
  SIGNATURE_GATHERING: 'Get Signatures',
  OFFICE_OPENING: 'Office Opening',
  TRAINING: 'Training',
};

/**
 * Class wrapper around GeoJSON returned from Mobilize events
 */
export default class Event {
  constructor(data, type = TYPE_TRAVIS) {
    const props = data.properties;
    const tType = type === TYPE_TRAVIS;
    this.isEvent = true;
    this.tType = tType;
    this.coordinates = data.geometry.coordinates;
    this.description = tType
      ? props.description
      : props.Description.replace(/<br>/g, ' ');

    if (tType) {
      this.url = props.browser_url;
      this.id = String(props.id).toLowerCase();
    } else {
      this.url = tType ? props.browser_url : urlExtractor(props['Sign Up']);

      this.id = this.url
        .split('event/')[1]
        .split('/')[0]
        .toLowerCase();
    }

    this.type = tType ? props.event_type : props['Type'];

    // process location
    this.venue = tType ? props.location.venue : props['Venue'];
    if (this.venue && this.venue.includes('is private')) {
      this.venue = null;
    }

    this.addressParts = [];
    if (tType) {
      const address1 = (props.location.address_lines || [])
        .filter(Boolean)
        .join(', ');

      if (address1) {
        this.addressParts.push(address1);
      }
      if (
        props.location.locality &&
        props.location.region &&
        props.location.postal_code
      ) {
        this.addressParts.push(
          `${props.location.locality}, ${props.location.region} ${props.location.postal_code}`
        );
      }
    } else {
      this.addressParts = props.Address.split('<br>');
    }

    this.title = tType ? props.title : props['Title'];
    this.isImportant = !!props.high_priority;

    this.timezone = tType ? props.timezone : data.timezone;
    const rawTimeslots = tType ? props.timeslots : data.timeslots;

    // assume that timeslots are ordered
    this.timeslots = rawTimeslots.reduce((acc, t) => {
      const timeslot = {
        start: convertToDate(t['start_date']),
        end: convertToDate(t['end_date']),
      };

      if (isFuture(timeslot.end) || isToday(timeslot.start)) {
        acc.push(timeslot);
      }

      return acc;
    }, []);

    if (this.timeslots.length > 0) {
      this.timeStart = this.timeslots[0].start;
      this.timeEnd = this.timeslots[0].end;
    } else {
      this.inPast = true;
    }

    this.searchDescription = `${this.description} ${this.address1} ${this.address2}`;
  }

  // See if this event passes the given date function.
  // Used for filtering events
  checkDateFunc(dateFunc) {
    for (let i = 0; i < this.timeslots.length; i++) {
      const x = this.timeslots[i];
      if (dateFunc(x.start)) {
        return true;
      }
    }
    return false;
  }

  get eventTitle() {
    return TYPE_TITLE_MAP[this.type] || TYPE_TITLE_MAP.OTHER;
  }

  get EventIcon() {
    return TYPE_ICON_MAP[this.type] || TYPE_ICON_MAP.OTHER;
  }

  get time() {
    return this.getTime();
  }

  get mapUri() {
    if (this.addressParts.length > 1) {
      if (this.addressParts[0].includes('is private')) {
        return null;
      }
      const e = encodeURIComponent;
      return `//maps.google.com/?q=${e(this.addressParts.join(' '))}`;
    }
    return null;
  }

  getTime(useTz = false) {
    const formatted = formattedRange(
      this.timeStart,
      this.timeEnd,
      useTz ? this.timezone : null
    );
    return formatted ? formatted : '';
  }

  getTimeSlots() {
    return this.timeslots.map(x =>
      formattedRange(x.start, x.end, this.timezone)
    );
  }

  get dateShort() {
    if (this.timeStart) {
      return format(this.timeStart, 'LLL d');
    }
    return '';
  }

  get position() {
    const lat = Number(this.coordinates[1]);
    const lng = Number(this.coordinates[0]);
    if (isFinite(lat) && isFinite(lng)) {
      return L.latLng(lat, lng);
    }
    console.warn('No position on ', this.id);
    return null;
  }

  getUri(baseUrl = '/') {
    return `${baseUrl}?event=${this.id}`;
  }

  getListUri(baseUrl) {
    return `${this.getUri(baseUrl)}&${URI_PARAM_PAN}=0`;
  }

  renderCard() {
    return <EventCard event={this} />;
  }
}

/*
Data example:
{
  "type": "FeatureCollection",
  "features": [{
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -97.132281,
        33.2148764
      ]
    },
    "properties": {
      "accessibility_status": "ACCESSIBLE",
      "address_visibility": "PUBLIC",
      "browser_url": "https://www.mobilize.us/yang2020/event/138765/",
      "created_by_volunteer_host": true,
      "created_date": 1570748212,
      "description": "Join us in Denton, TX for our weekly Yang Gang Hang at Jupiter House Coffee! Each week we'll be discussing new developments in the Yangosphere, talking policy, strategy and tactics, and just generally spreading the positive Yang Gang vibes.  Bring a friend, and feel free to come with questions, talking points and new ideas. This is a great way to get plugged into the DFW Yang2020 community at large, so swing by say hello, and help get the word out about the most important political candidate of the 2020 election season, Andrew Yang!",
      "event_type": "MEETING",
      "featured_image_url": "https://mobilizeamerica.imgix.net/uploads/organization/190503_seattle-2_20190904205718665386.jpg",
      "high_priority": true,
      "id": 138765,
      "location": {
        "address_lines": [
          "106 N Locust St",
          ""
        ],
        "congressional_district": "26",
        "country": "US",
        "locality": "Denton",
        "location": {
          "latitude": 33.2148764,
          "longitude": -97.132281
        },
        "postal_code": "76201",
        "region": "TX",
        "state_leg_district": "64",
        "state_senate_district": "30"
      },
      "modified_date": 1571255474,
      "sponsor": {
        "candidate_name": "Andrew Yang",
        "created_date": 1554388751,
        "event_feed_url": "https://www.mobilize.us/yang2020/",
        "id": 1396,
        "is_coordinated": true,
        "is_primary_campaign": true,
        "modified_date": 1573845984,
        "name": "Yang2020",
        "org_type": "CAMPAIGN",
        "race_type": "PRESIDENTIAL",
        "slug": "yang2020"
      },
      "timeslots": [
        {
          "id": 753886,
          "start_date": 1571097600,
          "end_date": 1571103000
        },
        ...
        {
          "id": 765545,
          "start_date": 1582592400,
          "end_date": 1582597800
        }
      ],
      "timezone": "America/Chicago",
      "title": "Denton Yang Gang Hang",
      "visibility": "PUBLIC",
      "lat": 33.2148764,
      "lng": -97.132281
    }
  }, ...]
}

Howard schema example
{
  "type": "Feature",
  "properties": {
      "Priority": "HIGH",
      "Title": "Andrew Yang in Keene, NH!",
      "Type": "TOWN_HALL",
      "Host": "Campaign",
      "Time": "Mon, Dec 2, 4:30PM-6:00PM EST",
      "Venue": "Branch and Blade Brewing Company, Inc.",
      "Address": "17 Bradco St<br>Keene, NH 03431",
      "Sign Up": "<a href=\"https://www.mobilize.us/nhforyang/event/162442/\">Mobilize US</a>",
      "Description": "Join Andrew Yang for a town hall in Keene, NH! Listen to his vision for the country and ask your questions.",
      "TimeStart": 1575322200,
      "TimeEnd": 1575327600,
      "marker-color": "#DA3248",
      "marker-symbol": "c",
      "marker-size": "medium",
      "timeslots": [...]
  },
  "geometry": {
      "type": "Point",
      "coordinates": [
          -83.9185727,
          35.964356
      ]
  }
},
*/
