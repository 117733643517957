import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import APIWrapperContext from '../components/APIWrapper/APIWrapperContext';
import Title from '../components/Title';
import filter from 'lodash/filter';
import VirtualEventListItem from '../components/VirtualEventListItem';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  highlight: {
    backgroundColor: theme.palette.gold.paper,
  },
}));

export default function VirtualEventsPage({ location }) {
  const classes = useStyles();

  const { hash } = location;
  const { events } = React.useContext(APIWrapperContext);
  const potentialId = hash.replace('#', '');
  const virtualEvents = React.useMemo(() => {
    return filter(events, x => x.isVirtual);
  }, [events]);
  // scroll down to active element
  React.useEffect(() => {
    if (potentialId && virtualEvents.length) {
      // loaded
      const potentialMatch = document.getElementById(potentialId);
      if (potentialMatch) {
        setTimeout(() => {
          const top = potentialMatch.offsetTop;
          window.scrollTo(0, top - 90);
        }, 100);
      }
    }
  }, [potentialId, virtualEvents]);
  return (
    <Container maxWidth="sm">
      <Box py={2}>
        <Box px={2}>
          <Title variant="h5">Virtual Events</Title>
        </Box>
        <List>
          {virtualEvents.map((event, idx) => (
            <React.Fragment key={event.id}>
              {idx > 0 && <Divider variant="inset" component="li" />}
              <VirtualEventListItem
                event={event}
                id={event.id}
                className={clsx({
                  [classes.highlight]: potentialId === event.id,
                })}
              />
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box py={3}>
        <Typography variant="body2" gutterBottom>
          If you have a virtual event that you'd like to list here, please
          contact your YGRO. (If you are a YGRO, please contact Dan Tramte or
          John McDermott.)
        </Typography>
        <Typography variant="body2" gutterBottom>
          If an event is listed here that you don't want displayed nationally
          (e.g. only in a certain state), we recommend recreating on Mobilize as
          an in-person event with a zip code corresponding to the location where
          you want it shown, then using the event description to explain that it
          is an online event.
        </Typography>
      </Box>
    </Container>
  );
}
