export default [
  {
    state: 'MD',
    window: 'January 7 - January 24',
    deadline: '1/24/2020',
    email: 'maryland.yang.gang@gmail.com',
  },
  {
    state: 'PA',
    window: 'By January 27',
    deadline: '1/27/2020',
    email: 'pa.yang.gang@gmail.com',
  },
  {
    state: 'PR',
    window: 'January 2 - January 29',
    deadline: '1/29/2020',
    email: 'aurelio.agelviz@gmail.com',
  },
  {
    state: 'AS',
    window: 'By January 31',
    deadline: '1/31/2020',
    email: 'hawaiiro.yang.gang@gmail.com',
  },
  {
    state: 'NY',
    window: 'January 2 - February 4',
    deadline: '2/4/2020',
    email: 'nyyanggang@yanggangmail.com',
  },
  {
    state: 'TN',
    window: 'January 6 - February 20',
    deadline: '2/20/2020',
    email: 'info@tn4yang.com',
  },
  {
    state: 'OK',
    window: 'January 6 - February 26',
    deadline: '2/26/2020',
    email: 'oklahoma.yang.gang@gmail.com',
  },
  {
    state: 'RI',
    window: 'February 26 - February 27',
    deadline: '2/27/2020',
    email: 'rhodeisland.yang.gang@gmail.com',
  },
  {
    state: 'CA',
    window: 'January 6 - March 3',
    deadline: '3/3/2020',
    email: 'california.yang.gang@gmail.com',
  },
  {
    state: 'MP',
    window: 'By March 5',
    deadline: '3/5/2020',
    email: '',
  },
  {
    state: 'FL',
    window: 'February 3 - March 7',
    deadline: '3/7/2020',
    email: 'fl.yang.gang@gmail.com',
  },
  {
    state: 'SD',
    window: 'Feburary 10 - March 9',
    deadline: '3/9/2020',
    email: 'minnesota.yang.gang@gmail.com',
  },
  {
    state: 'AZ',
    window: 'January 2 - March 13',
    deadline: '3/13/2020',
    email: 'azyanggang@gmail.com',
  },
  {
    state: 'ND',
    window: 'January 6 - March 13',
    deadline: '3/13/2020',
    email: 'justjuicewells@gmail.com',
  },
  {
    state: 'DC',
    window: 'By March 21',
    deadline: '3/21/2020',
    email: 'district.yang.gang@gmail.com',
  },
  {
    state: 'CO',
    window: 'January 6 - March 23',
    deadline: '3/23/2020',
    email: 'colorado.yang.gang@gmail.com',
  },
  {
    state: 'NC',
    window: 'By March 26',
    deadline: '3/26/2020',
    email: 'nc.yang.gang@gmail.com',
  },
  {
    state: 'MA',
    window: 'January 13 - March 27',
    deadline: '3/27/2020',
    email: 'massachusetts.yang.gang@gmail.com',
  },
  {
    state: 'MS',
    window: 'By March 27',
    deadline: '3/27/2020',
    email: 'kelsey.holleman@gmail.com,wuben101@gmail.com',
  },
  {
    state: 'UT',
    window: 'March 3 - March 27',
    deadline: '3/27/2020',
    email: 'ut.yang.gang@gmail.com',
  },
  {
    state: 'GA',
    window: 'February 1 - March 30',
    deadline: '3/30/2020',
    email: 'georgia.yang.gang@gmail.com,arnavrao@live.com',
  },
  {
    state: 'LA',
    window: 'March 13 - April 6',
    deadline: '4/6/2020',
    email: 'j3enator@gmail.com',
  },
  {
    state: 'DE',
    window: 'January 1 - April 9',
    deadline: '4/9/2020',
    email: 'delaware.yang.gang@gmail.com',
  },
  {
    state: 'IA',
    window: 'March 22 - April 13',
    deadline: '4/13/2020',
    email: 'iowa.yang.gang@gmail.com',
  },
  {
    state: 'MI',
    window: 'By April 16',
    deadline: '4/16/2020',
    email: 'michigan.yang.gang@gmail.com',
  },
  {
    state: 'MO',
    window: 'March 15 - April 16',
    deadline: '4/16/2020',
    email: 'missouri.yang.gang@gmail.com',
  },
  {
    state: 'Abroad',
    window: 'By April 17',
    deadline: '4/17/2020',
    email: '',
  },
  {
    state: 'GU',
    window: 'By April 17',
    deadline: '4/17/2020',
    email: 'hawaiiro.yang.gang@gmail.com',
  },
  {
    state: 'NH',
    window: 'March 1 - April 17',
    deadline: '4/17/2020',
    email: 'newhampshire.yang.gang@gmail.com',
  },
  {
    state: 'VA',
    window: 'By April 17',
    deadline: '4/17/2020',
    email: 'virginia.yang.gang@gmail.com',
  },
  {
    state: 'WI',
    window: 'February 10 - April 17',
    deadline: '4/17/2020',
    email: 'wisconsin.yang.gang@gmail.com',
  },
  {
    state: 'AK',
    window: 'By April 30',
    deadline: '4/30/2020',
    email: 'alaska.yang.gang@gmail.com',
  },
  {
    state: 'SC',
    window: 'By April 30',
    deadline: '4/30/2020',
    email: 'southcarolina.yang.gang@gmail.com',
  },
  {
    state: 'NE',
    window: 'On May 2',
    deadline: '5/2/2020',
    email: 'nebraska.yang.gang@gmail.com',
  },
  {
    state: 'WA',
    window: 'March 23 - May 3',
    deadline: '5/3/2020',
    email: 'washington.yang.gang@gmail.com',
  },
  {
    state: 'CT',
    window: 'By May 4',
    deadline: '5/4/2020',
    email: 'connecticut.yang.gang@gmail.com',
  },
  {
    state: 'AR',
    window: 'April 30 - May 7',
    deadline: '5/7/2020',
    email: 'ARforYang@gmail.com',
  },
  {
    state: 'HI',
    window: 'March 1 - May 7',
    deadline: '5/7/2020',
    email: 'hawaiiro.yang.gang@gmail.com',
  },
  {
    state: 'KY',
    window: 'February 2 - May 8',
    deadline: '5/8/2020',
    email: 'KentuckyforYang@gmail.com',
  },
  {
    state: 'WV',
    window: 'January 8 - May 12',
    deadline: '5/12/2020',
    email: 'westvirginia.yang.gang@gmail.com',
  },
  {
    state: 'NM',
    window: 'March 1 - May 13',
    deadline: '5/13/2020',
    email: 'newmexico.yang.gang@gmail.com',
  },
  {
    state: 'ME',
    window: 'By May 15',
    deadline: '5/15/2020',
    email: 'maine.yang.gang@gmail.com',
  },
  {
    state: 'VT',
    window: 'March 10 - May 15',
    deadline: '5/15/2020',
    email: 'vermont.yang.gang@gmail.com',
  },
  {
    state: 'KS',
    window: 'By May 16',
    deadline: '5/16/2020',
    email: 'kansas.yang.gang@gmail.com',
  },
  {
    state: 'OR',
    window: 'March 1 - May 21',
    deadline: '5/21/2020',
    email: 'oregon.yang.gang@gmail.com',
  },
  {
    state: 'TX',
    window: 'March 23 - May 25',
    deadline: '5/25/2020',
    email: 'texas.yang.gang@gmail.com',
  },
  {
    state: 'NV',
    window: 'By May 29',
    deadline: '5/29/2020',
    email: 'nevada.yang.gang@gmail.com',
  },
  {
    state: 'MN',
    window: 'By May 31',
    deadline: '5/31/2020',
    email: 'minnesota.yang.gang@gmail.com',
  },
  {
    state: 'IN',
    window: 'May 1 - June 1',
    deadline: '6/1/2020',

    email:
      'Indiana.austin@gmail.com,a.emily.89@gmail.com,ramtinthang2013@gmail.com',
  },
  {
    state: 'WY',
    window: 'By June 1',
    deadline: '6/1/2020',
    email: 'colorado.yang.gang@gmail.com',
  },
  {
    state: 'ID',
    window: 'By June 5',
    deadline: '6/5/2020',
    email: 'yang2020.id@gmail.com',
  },
  {
    state: 'MT',
    window: 'By June 5',
    deadline: '6/5/2020',
    email: 'yanggangmt@gmail.com',
  },
  {
    state: 'NJ',
    window: 'By June 5',
    deadline: '6/5/2020',
    email: 'nj.yang.gang@gmail.com',
  },
  {
    state: 'VI',
    window: 'On June 6',
    deadline: '6/6/2020',
    email: '',
  },
  {
    state: 'AL',
    window: 'September 27 - November 8',
    deadline: '11/8/2019',
    email: 'alabama.yang.gang@gmail.com',
  },
  {
    state: 'OH',
    window: 'By December 31',
    deadline: '12/31/2019',
    email: 'ohio.yang.gang@gmail.com',
  },
  {
    state: 'IL',
    window: 'By January 3',
    deadline: '1/3/2020',
    email: 'official.illinois.yang.gang@gmail.com',
  },
];
