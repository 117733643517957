import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import EmailLink from '../EmailLink';
import SocialIconsList from '../SocialIconsList';
import Address from '../Address';

export default function FieldOfficeCard({ fieldOffice }) {
  return (
    <Box>
      <Typography variant="overline">Yang2020 Field Office</Typography>
      <Typography variant="subtitle1" gutterBottom>
        {fieldOffice.name}
      </Typography>
      <Address entity={fieldOffice} />

      {fieldOffice.email && (
        <EmailLink
          email={fieldOffice.email}
          title="Email"
          ygName={fieldOffice.title}
          cc={fieldOffice.regionalEmail}
        />
      )}
      {fieldOffice.hours && (
        <Typography gutterBottom component="div">
          <Typography variant="caption">Office Hours</Typography>
          <br />
          {fieldOffice.hours}
        </Typography>
      )}

      <SocialIconsList entity={fieldOffice} />
    </Box>
  );
}

FieldOfficeCard.defaultProps = {};
