import qs from 'query-string';

export default function persistUri(passed) {
  if (window.history.pushState) {
    // given current window location, add parameters to it
    const current = qs.parse(window.location.search);
    const next = Object.assign({}, current, passed);
    const newURL = new URL(window.location.href);
    newURL.search = `?${qs.stringify(next)}`;
    window.history.pushState({ path: newURL.href }, '', newURL.href);
  }
}
