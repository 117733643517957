/**
 * Allow setting URLs via ENV
 */
export const EVENTS_URL = process.env.REACT_APP_EVENTS_URL || '/events.geojson';
export const BALLOT_PROGRESS_URL =
  process.env.REACT_APP_EVENTS_URL ||
  'https://raw.githubusercontent.com/howardn23/yanggang/master/signatures.json';
export const YANG_GANGS_URL =
  process.env.REACT_APP_YANG_GANGS_URL ||
  'https://raw.githubusercontent.com/howardn23/yanggang/master/yanggangUpdated.geojson';
export const HOST_EVENT_URL =
  process.env.HOST_EVENT_URL ||
  'https://docs.google.com/forms/d/e/1FAIpQLSdP8fIeeVV5_ySSoIHaprBFP5zuJZNq5f9nFVTBDhUsUkyXnA/viewform';
export const VOLUNTEER_URL =
  process.env.VOLUNTEER_URL || 'https://yang2020.com/volunteer';

export const USE_VECTOR_MAP = process.env.USE_VECTOR_MAP || 'true';
export const VECTOR_TILE_LAYER_STYLE =
  process.env.VECTOR_TILE_LAYER_STYLE ||
  'https://api.maptiler.com/maps/bright/style.json?key=ULcXw01mfbRvGHQd3eBj';
export const TILE_LAYER_URL =
  process.env.TILE_LAYER_URL ||
  'https://api.maptiler.com/maps/bright/{z}/{x}/{y}{r}.png?key=ULcXw01mfbRvGHQd3eBj';
export const TILE_LAYER_SIZE = process.env.TILE_LAYER_SIZE || 512;
export const TILE_LAYER_ZOOM_OFFSET = process.env.TILE_LAYER_ZOOM_OFFSET || -1;
export const TILE_LAYER_ATTRIB =
  process.env.TILE_LAYER_ATTRIB ||
  '© <a href="https://www.maptiler.com/copyright/">MapTiler</a> © <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
