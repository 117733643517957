import axios from 'axios';
import React from 'react';
import { YANG_GANGS_URL } from '../config';
import YangGang from '../entities/YangGang';
import VirtualEvent from '../entities/VirtualEvent';
import Event, { TYPE_HOWARD } from '../entities/Event';
import get from 'lodash/get';
/**
 * Get data from Howard's data source. Howard's contains both virtual events and Yang Gangs (as well as regular events, but those are coming from another API right now)
 */
export default function useYangGangs() {
  const [data, setData] = React.useState({
    yangGangs: [],
    events: [],
    fieldOffices: [],
    isLoaded: false,
  });

  React.useEffect(() => {
    async function fetchData() {
      const result = await axios(YANG_GANGS_URL, {
        responseType: 'json',
      });
      const features = get(result, 'data.features', []).reduce(
        (acc, entry) => {
          if (entry.properties['Field_Office_Name']) {
            // Drop field offices for now.
            // acc.fieldOffices.push(new FieldOffice(entry));
          } else if (entry.properties['Yang_Gang_Name']) {
            acc.yangGangs.push(new YangGang(entry));
          } else if (entry.properties['Address']) {
            const event =
              entry.properties['Address'] === 'Virtual'
                ? new VirtualEvent(entry, TYPE_HOWARD)
                : new Event(entry, TYPE_HOWARD);
            const id = event.id;
            // prevent duplicates
            if (!acc.eventIds.includes(id)) {
              acc.events.push(event);
              acc.eventIds.push(id);
            }
          } else {
            // no handling
            console.log('unsupported', entry);
          }
          return acc;
        },
        {
          yangGangs: [],
          events: [],
          eventIds: [],
          fieldOffices: [],
          isLoaded: true,
        }
      );

      setData(features);
    }
    fetchData();
  }, [setData]);

  return data;
}
