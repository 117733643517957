import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import qs from 'query-string';
import React from 'react';
import slugify from 'slugify';
import APIWrapperContext from '../components/APIWrapper/APIWrapperContext';
import Map, { INITIAL_ZOOM } from '../components/Map';
import { HOST_EVENT_URL } from '../config';
import ALL_STATES from '../constants/states';
import {
  URI_PARAM_CENTER,
  URI_PARAM_DATE,
  URI_PARAM_EVENT,
  URI_PARAM_FIELD_OFFICE,
  URI_PARAM_PAN,
  URI_PARAM_TYPE,
  URI_PARAM_YANG_GANG,
  URI_PARAM_ZOOM,
} from '../constants/uri';
import { TOOLBAR_HEIGHT, TOOLBAR_HEIGHT_LG, TOOLBAR_HEIGHT_XS } from '../theme';

const SHOW_FAB = false;
const useStyles = makeStyles(theme => ({
  map: {
    height: `calc(100vh - ${TOOLBAR_HEIGHT}px)`,
    width: '100%',
    // hardcoded to match theme.mixins.toolbar
    '@media (min-width:0px) and (orientation: landscape)': {
      height: `calc(100vh - ${TOOLBAR_HEIGHT_XS}px)`,
    },
    '@media (min-width:600px)': {
      height: `calc(100vh - ${TOOLBAR_HEIGHT_LG}px)`,
    },
  },
  mapEmbedded: {
    height: '100vh',
  },
  fab: {
    position: 'fixed',
    zIndex: theme.zIndex.appBar,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const INITIAL_CENTER = [39.8, -98.6];
const ACTIVE_ENTITY_PARAMS = [
  URI_PARAM_YANG_GANG,
  URI_PARAM_FIELD_OFFICE,
  URI_PARAM_EVENT,
];

function forceArray(strOrArray, defaultValue) {
  if (isArray(strOrArray)) {
    return strOrArray;
  }
  if (strOrArray) {
    return [strOrArray];
  }
  return defaultValue || [];
}

const stateFilter = (state, stateAbbr) => x => {
  if (x.isYangGang) {
    return x.location.includes(state);
  }
  if (x.isFieldOffice) {
    return x.state === state;
  }
  if (x.isEvent) {
    return x.addressParts.join(' ').includes(`, ${stateAbbr}`);
  }
  if (x.isVirtual) {
    return x.title.includes(state);
  }
  return true;
};

function getStateFilter(idLower) {
  if (idLower) {
    const matchId = idLower.toUpperCase();
    const state = ALL_STATES.find(
      x => x.id === matchId || slugify(x.name).toUpperCase() === matchId
    );

    if (state) {
      const { center, name, zoom, typeFilters, id } = state;
      const props = {
        id: id.toLowerCase(),
        uri: `/${idLower}`,
        zoom: zoom || 6,
        center,
        filter: stateFilter(name, id),
        searchTitle: `Search ${name}…`,
        typeFilters: typeFilters || [],
      };
      return props;
    }
  }

  return null;
}

export default function MapsPage({
  location,
  isDesktop,
  isEmbedded,
  isMinimal,
  state,
}) {
  const classes = useStyles();
  const searchParams = qs.parse(location.search);
  const pan = searchParams[URI_PARAM_PAN];
  const c = searchParams[URI_PARAM_CENTER];
  const z = searchParams[URI_PARAM_ZOOM];
  const activeFilter = getStateFilter(state);
  const dateFilters = forceArray(searchParams[URI_PARAM_DATE]);
  // if there's a state active, start by filtering to field offices
  const typeFilters = forceArray(
    searchParams[URI_PARAM_TYPE],
    get(activeFilter, 'typeFilters', [])
  );

  const zoom = (z && Number(z)) || get(activeFilter, 'zoom', INITIAL_ZOOM);
  let center = get(activeFilter, 'center', INITIAL_CENTER);
  let shouldFit = true;
  if (c) {
    // if a center point is explicitly passed, don't try to fit the map
    const cParts = c.split(',').map(Number);
    if (cParts.length === 2 && isFinite(cParts[0]) && isFinite(cParts[1])) {
      center = cParts;
      shouldFit = false;
    }
  }

  const activeEntityType = ACTIVE_ENTITY_PARAMS.find(x => !!searchParams[x]);
  const activeEntitySlug = activeEntityType
    ? searchParams[activeEntityType]
    : null;

  const { updateFilterObj } = React.useContext(APIWrapperContext);

  React.useEffect(() => {
    updateFilterObj(activeFilter);
  }, [activeFilter, updateFilterObj]);

  return (
    <>
      {SHOW_FAB && (
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          href={HOST_EVENT_URL}
          rel="noopener noreferrer"
          target="_blank"
        >
          <AddIcon />
        </Fab>
      )}
      <Map
        activeEntitySlug={activeEntitySlug}
        activeEntityType={activeEntityType}
        activeFilter={activeFilter}
        className={clsx(classes.map, {
          [classes.mapEmbedded]: isEmbedded,
        })}
        initialCenter={center}
        initialDateFilters={dateFilters}
        initialTypeFilters={typeFilters}
        initialZoom={zoom}
        isMinimal={isMinimal}
        isDesktop={isDesktop}
        isEmbedded={isEmbedded}
        pan={pan}
        shouldFit={shouldFit}
      />
    </>
  );
}
