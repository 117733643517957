import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  wrapper: {},
  button: {
    margin: theme.spacing(0.5, 1, 0.5, 0),
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none',
    borderRadius: 100,
  },
  buttonSelected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[300],
  },
}));

/**
 * Provides interface for filtering by date. Intended to just be a regular
 * component but turned into a HOC. Could potentially be implemented with context
 */
export default function DateFilterList({
  eligibleRanges,
  activeDateFilter,
  setActiveDateFilter,
  toggleActiveDateFilter,
  ...otherProps
}) {
  const classes = useStyles();

  return (
    <div {...otherProps} className={classes.wrapper}>
      <Box px={1}>
        <FormControlLabel
          control={
            <Checkbox
              checked={activeDateFilter.length === 0}
              onChange={() => setActiveDateFilter([])}
              color="primary"
              value=""
            />
          }
          label={'All Dates'}
        />
      </Box>
      {eligibleRanges.map(({ title, key }) => {
        const selected = activeDateFilter.includes(key);
        return (
          <Box px={1} key={key}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selected}
                  onChange={() => toggleActiveDateFilter(key)}
                  color="primary"
                />
              }
              label={title}
            />
          </Box>
        );
      })}
    </div>
  );
}

DateFilterList.defaultProps = {
  items: [],
};
