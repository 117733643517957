import L from 'leaflet';
import { GeoJSON } from 'leaflet';
import get from 'lodash/get';
import stateBorders from '../constants/stateBorders';
import delegateInfo from '../constants/delegateInfo';
import states from '../constants/states';
import parse from 'date-fns/parse';
import differenceInDays from 'date-fns/differenceInDays';
export const STATUS_DONE = 'DONE';
export const STATUS_IMPORTANT = 'IMPORTANT';
export const STATUS_REGULAR = 'REGULAR';
export const STATUS_LOW = 'LOW';
export const STATUS_PENDING = 'PENDING';

const STATUS_COLOR_MAP = {
  [STATUS_PENDING]: '#666',
  [STATUS_DONE]: '#133567',
  [STATUS_IMPORTANT]: '#DA3248',
  [STATUS_REGULAR]: '#F2A00F',
  [STATUS_LOW]: '#6CACE4',
};

const STATUS_VALUE_MAP = {
  [STATUS_PENDING]: -1,
  [STATUS_DONE]: 0,
  [STATUS_IMPORTANT]: 3,
  [STATUS_REGULAR]: 2,
  [STATUS_LOW]: 1,
};

const STATUS_MAPPING = {
  'Ballot done without signature gathering': STATUS_DONE,
  'Ballot done via signature gathering!': STATUS_DONE,
  'Currently gathering: 60-89% complete': STATUS_REGULAR,
  'Currently gathering: 30-59% complete': STATUS_REGULAR,
  'Currently gathering: 0-29% complete': STATUS_IMPORTANT,
  'Scheduled to begin gathering signatures': STATUS_LOW,
  'Does not currently require signatures': STATUS_PENDING,
  'Signatures submitted – awaiting approval': STATUS_DONE,
};

/**
 * Class wrapper around https://docs.google.com/spreadsheets/d/1bmB9x2xY1-xLgR5GdCkTZj2J56YqpHKMTplf29EkdI8/edit#gid=0 data
 */
export default class BallotProgress {
  constructor(props) {
    this.isBallotProgress = true;
    this.state = props['State']; // 2 letter abbreviation
    this.id = this.state;
    this.rawStatus = props['Signature gathering status'];
    this.startDate = props['Date to begin'];
  }

  get position() {
    if (this.stateObj) {
      return L.latLng(this.stateObj.center[0], this.stateObj.center[1]);
    }
    return null;
  }

  get status() {
    return STATUS_MAPPING[this.rawStatus] || STATUS_REGULAR;
  }

  get statusValue() {
    return STATUS_VALUE_MAP[this.status];
  }

  get listOrder() {
    return this.delegateDeadlineDayCount > -2
      ? this.delegateDeadlineDayCount
      : this.delegateDeadlineDayCount + 1000;
  }

  get shouldDisplayInList() {
    return true;
    // return this.statusValue > 0 || this.isDelegatePriority;
  }

  get isBallotPriority() {
    return this.statusValue > 1;
  }

  get isDelegatePriority() {
    return (
      this.delegateDeadlineDayCount >= 0 && this.delegateDeadlineDayCount < 25
    );
  }

  get stateName() {
    return get(this.stateObj, 'name', this.state);
  }

  get stateObj() {
    return states.find(x => x.id === this.state);
  }

  get visible() {
    return this.stateObj && this.stateObj.territory === false;
  }

  get geometry() {
    if (this.stateObj) {
      const bordersObj = stateBorders.find(
        x => x.properties.name === this.stateObj.name
      );
      return get(bordersObj, 'geometry', null);
    }
    return null;
  }

  get delegateObj() {
    return delegateInfo.find(x => x.state === this.state);
  }

  get delegateDeadline() {
    if (this.delegateObj) {
      const { deadline } = this.delegateObj;
      const date = parse(deadline, 'P', new Date());
      return date;
    }
    return null;
  }

  get delegateDeadlineDayCount() {
    if (this.delegateDeadline) {
      return differenceInDays(this.delegateDeadline, new Date());
    }
    return 100;
  }

  get positions() {
    if (this.geometry) {
      const { type, coordinates } = this.geometry;
      return GeoJSON.coordsToLatLngs(coordinates, type === 'Polygon' ? 1 : 2);
    }
    return null;
  }

  get color() {
    return STATUS_COLOR_MAP[this.status];
  }
}

/*
Data example:
[
  {
    "State": "AL",
    "Signature gathering status": "Ballot done via signature gathering!",
    "Date to begin": "",
  }, ...]
}*/
