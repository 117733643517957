import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import RedditIcon from '@material-ui/icons/Reddit';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import React from 'react';
import SlackIcon from '../SlackIcon';

const SOCIAL_SERVICES = [
  {
    title: 'Facebook',
    Icon: FacebookIcon,
    key: 'facebook',
  },
  {
    title: 'Twitter',
    Icon: TwitterIcon,
    key: 'twitter',
  },
  {
    title: 'Instagram',
    Icon: InstagramIcon,
    key: 'instagram',
  },
  {
    title: 'YouTube',
    Icon: YouTubeIcon,
    key: 'youtube',
  },
  {
    title: 'Reddit',
    Icon: RedditIcon,
    key: 'reddit',
  },
  {
    title: 'Slack',
    Icon: SlackIcon,
    key: 'slack',
    notice: (
      <>
        To be invited to your regional Slack workspace, contact your Yang Gang
        Primary Contact or Regional Organizer. Thank you!
      </>
    ),
  },
];

/**
 * Absolutely terrifying way to attempt to have valid URLs
 * @param {string} url
 */
function hackUrl(url) {
  if (url && !url.startsWith('http')) {
    return `https://${url}`;
  }
  return url;
}

export default function SocialIconsList({ entity }) {
  const [active, setActive] = React.useState(null);
  const handleClose = () => setActive(null);
  return (
    <>
      {SOCIAL_SERVICES.reduce((acc, { key, Icon, title, notice }) => {
        const url = hackUrl(entity[key]);
        if (url) {
          const addlProps =
            acc.length === 0
              ? {
                  edge: 'start',
                }
              : {};
          const otherProps = {};
          if (notice) {
            otherProps.onClick = e => {
              e.preventDefault();
              setActive({ title, url, notice });
            };
          }
          acc.push(
            <IconButton
              key={title}
              component="a"
              href={url}
              rel="noopener noreferrer"
              target="_blank"
              title={title}
              aria-label={`Join on ${title}`}
              {...addlProps}
              {...otherProps}
            >
              <Icon />
            </IconButton>
          );
        }
        return acc;
      }, [])}
      <Dialog
        open={!!active}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {active ? (
          <>
            <DialogTitle id="alert-dialog-title">{active.title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {active.notice}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              <Button
                component="a"
                href={active.url}
                rel="noopener noreferrer"
                target="_blank"
                onClick={handleClose}
                color="primary"
                autoFocus
              >
                Open {active.title}
              </Button>
            </DialogActions>
          </>
        ) : (
          <br />
        )}
      </Dialog>
    </>
  );
}
