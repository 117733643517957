import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import React from 'react';

export default function YGEditModal({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Editing Yang Gangs</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          To add, update, or remove a Yang Gang, please contact your YGRO (or
          the nearest regional contact to you). If you still need help, reach
          out to us at{' '}
          <Link href="mailto:contact@yangnearme.com">
            contact@yangnearme.com
          </Link>
          . Thank you!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}
