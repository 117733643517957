import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import React from 'react';
import EmailLink from '../EmailLink';
import SocialIconsList from '../SocialIconsList';

export default function YangGangCard({ yangGang }) {
  return (
    <Box>
      <Typography variant="overline">Yang Gang</Typography>
      <Typography variant="subtitle1" gutterBottom>
        {yangGang.name}
        {', '}
        {yangGang.location.split(', ')[1]}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="div">
        {yangGang.email && (
          <EmailLink
            email={yangGang.email}
            title="Yang Gang Primary Contact Email"
            ygName={yangGang.title}
            cc={yangGang.regionalEmail}
          />
        )}
        {yangGang.regionalEmail && (
          <EmailLink
            email={yangGang.regionalEmail}
            title="Yang Gang Regional Organizer Email"
            ygName={yangGang.title}
            cc={yangGang.email}
          />
        )}
        {yangGang.website && (
          <Typography gutterBottom component="div">
            <Typography variant="caption">Website</Typography>
            <br />
            <Link href={yangGang.website}>{yangGang.website}</Link>
          </Typography>
        )}
      </Typography>
      <SocialIconsList entity={yangGang} />
    </Box>
  );
}

YangGangCard.defaultProps = {};
