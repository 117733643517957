import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';
import MapIcon from '@material-ui/icons/Map';
import clsx from 'clsx';
import React from 'react';
const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  reset: {
    marginTop: theme.spacing(1),
  },
  icon: { marginRight: theme.spacing(1) },
}));

export default function MapBottomBar({
  className,
  mobileOverlayMode,
  setMobileOverlayMode,
  hasSteps,
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper, className)}>
      <Button
        onClick={() => {
          setMobileOverlayMode(x => (x === 'mapList' ? null : 'mapList'));
        }}
      >
        {mobileOverlayMode === 'mapList' ? (
          <MapIcon className={classes.icon} />
        ) : (
          <ListIcon className={classes.icon} />
        )}{' '}
        {mobileOverlayMode === 'mapList' ? 'Show Map' : 'Show List'}
      </Button>
      {hasSteps && (
        <Button
          onClick={() => {
            setMobileOverlayMode(x => (x === 'steps' ? null : 'steps'));
          }}
        >
          {mobileOverlayMode === 'steps' ? (
            <CloseIcon className={classes.icon} />
          ) : (
            <AssignmentIcon className={classes.icon} />
          )}{' '}
          {mobileOverlayMode === 'steps' ? 'Close' : 'Game Plan'}
        </Button>
      )}
    </div>
  );
}
