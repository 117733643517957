import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import BallotProgressIcon from '@material-ui/icons/HowToVote';
import GroupIcon from '@material-ui/icons/Group';
import React from 'react';
import {
  URI_TYPE_ANY_EVENT,
  URI_TYPE_YG,
  URI_TYPE_BP,
} from '../../constants/uri';

const useStyles = makeStyles(theme => ({
  wrapper: {},
  button: {
    margin: theme.spacing(0.5, 1, 0.5, 0),
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none',
    borderRadius: 100,
  },
  buttonSelected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[300],
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  label: {
    display: 'flex',
  },
}));

function FilterListLine({ checked, onChange, Icon, text }) {
  const classes = useStyles();
  return (
    <Box px={1}>
      <FormControlLabel
        control={
          <Checkbox checked={checked} onChange={onChange} color="primary" />
        }
        label={
          <span className={classes.label}>
            {Icon && <Icon size="small" className={classes.icon} />} {text}
          </span>
        }
      />
    </Box>
  );
}

/**
 * Provides interface for filtering by date. Intended to just be a regular
 * component but turned into a HOC. Could potentially be implemented with context
 */
export default function EventTypeFilterList({
  activeEventTypeFilter,
  setActiveEventTypeFilter,
  eligibleEventTypes,
  toggleActiveEventTypeFilter,
  ...otherProps
}) {
  const classes = useStyles();

  return (
    <div {...otherProps} className={classes.wrapper}>
      <FilterListLine
        checked={activeEventTypeFilter.length === 0}
        onChange={() => setActiveEventTypeFilter([])}
        text="Everything"
      />
      <FilterListLine
        checked={activeEventTypeFilter.includes(URI_TYPE_YG)}
        onChange={() => toggleActiveEventTypeFilter(URI_TYPE_YG)}
        Icon={GroupIcon}
        text="Yang Gangs (YG)"
      />
      {/*
      <FilterListLine
        checked={activeEventTypeFilter.includes(URI_TYPE_FO)}
        onChange={() => toggleActiveEventTypeFilter(URI_TYPE_FO)}
        Icon={FieldOfficeIcon}
        text="Field Offices (FO)"
      />
      <FilterListLine
        checked={activeEventTypeFilter.includes(URI_TYPE_POPUP)}
        onChange={() => toggleActiveEventTypeFilter(URI_TYPE_POPUP)}
        Icon={FieldOfficeOutlinedIcon}
        text="Pop-up Field Office"
      />
      */}
      <FilterListLine
        checked={activeEventTypeFilter.includes(URI_TYPE_BP)}
        onChange={() => toggleActiveEventTypeFilter(URI_TYPE_BP)}
        Icon={BallotProgressIcon}
        text="Ballot Progress"
      />

      <FilterListLine
        checked={activeEventTypeFilter.includes(URI_TYPE_ANY_EVENT)}
        onChange={() => toggleActiveEventTypeFilter(URI_TYPE_ANY_EVENT)}
        Icon={EventIcon}
        text="All Events"
      />

      {eligibleEventTypes.map(({ text, key, Icon }) => {
        const selected = activeEventTypeFilter.includes(key);
        return (
          <FilterListLine
            checked={selected}
            key={key}
            onChange={() => toggleActiveEventTypeFilter(key)}
            Icon={Icon}
            text={text}
          />
        );
      })}
    </div>
  );
}

EventTypeFilterList.defaultProps = {
  items: [],
};
