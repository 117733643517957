import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import React from 'react';
import EventDescription from '../EventDescription';
import Address from '../Address';
import delimiter from 'delimiter';

const DEFAULT_TIMES_VISIBLE = 3;
const useStyles = makeStyles(() => ({
  header: {
    lineHeight: 1.5,
  },
}));

function EventTime({ event }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'event-card-time-popover' : undefined;
  if (event.timeslots.length === 0) {
    return null;
  }

  const slots = event
    .getTimeSlots()
    .slice(0, DEFAULT_TIMES_VISIBLE)
    .map((x, i) => <span key={i}>{x}</span>);
  if (event.timeslots.length > DEFAULT_TIMES_VISIBLE) {
    const addlLength = event.timeslots.length - DEFAULT_TIMES_VISIBLE;
    slots.push(
      <Link key="more" href="#" aria-describedby={id} onClick={handleClick}>
        {addlLength} more times
      </Link>
    );
  }

  return (
    <>
      <Typography gutterBottom variant="subtitle2">
        {delimiter(slots)}
      </Typography>

      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Box p={1}>
          {event
            .getTimeSlots()
            .slice(DEFAULT_TIMES_VISIBLE)
            .map((x, i) => (
              <Typography key={i}>{x}</Typography>
            ))}
        </Box>
      </Popover>
    </>
  );
}

export default function EventCard({ event }) {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="overline">{event.eventTitle} Event</Typography>
      <Typography gutterBottom className={classes.header} variant="subtitle1">
        {event.title}
      </Typography>
      <EventTime event={event} />
      {event.description && (
        <EventDescription text={event.description} url={event.url} />
      )}
      <Typography
        gutterBottom
        variant="body2"
        color="textSecondary"
        component="div"
      >
        <div>{event.venue}</div>
        <Address entity={event} />
      </Typography>
      <Box mt={2}>
        <Button
          component="a"
          href={event.url}
          rel="noopener noreferrer"
          target="_blank"
          variant="contained"
          color="secondary"
        >
          See Details and RSVP
        </Button>
      </Box>
    </Box>
  );
}

EventCard.defaultProps = {
  showImage: false,
};
