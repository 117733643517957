import React from 'react';
import Typography from '@material-ui/core/Typography';
import PriorityHighlight from './PriorityHighlight';
import Link from '@material-ui/core/Link';

const ENABLE_PRIORITY = false;

export default function BallotProgressDetails({ entry }) {
  return (
    <>
      <Typography variant="button">{entry.stateName}</Typography>
      <br />
      <PriorityHighlight priority={ENABLE_PRIORITY && entry.isBallotPriority}>
        <i>Ballot Access:</i> {entry.rawStatus} {entry.startDate}
      </PriorityHighlight>
      <br />
      <PriorityHighlight priority={ENABLE_PRIORITY && entry.isDelegatePriority}>
        <i>DSP Filing Window:</i> {entry.delegateObj.window}
      </PriorityHighlight>

      {entry.delegateObj.email && (
        <>
          <br />
          <i>State Contact:</i>{' '}
          <Link href={`mailto:${entry.delegateObj.email}`}>
            {entry.delegateObj.email}
          </Link>
        </>
      )}
    </>
  );
}
