import LocateIcon from '@material-ui/icons/MyLocation';
import React from 'react';
import Control from 'react-leaflet-control';
const LOCATE_ME_ZOOM = 7;

export default function LocateButton({ mapElem, ...otherProps }) {
  return (
    <Control position="topleft">
      <div className="leaflet-bar">
        <a
          title="Use Your Location"
          aria-label="Use Your location"
          role="button"
          href="#useLocation" // lying to the linter
          {...otherProps}
          onClick={() => {
            mapElem.locate({ setView: true, maxZoom: LOCATE_ME_ZOOM });
          }}
        >
          <LocateIcon fontSize="small" />
        </a>
      </div>
    </Control>
  );
}
