import sortBy from 'lodash/sortBy';
import React from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import APIWrapperContext from '../APIWrapper/APIWrapperContext';
import BallotProgressDetails from '../BallotProgressDetails';

export default function BallotProgressLayers() {
  const { ballotProgressStates } = React.useContext(APIWrapperContext);
  // sort in order to make sure important show up outlined on top of less important
  return sortBy(ballotProgressStates, 'statusValue').map(ballotProgress => {
    if (ballotProgress.visible && ballotProgress.positions) {
      return (
        <Polygon
          color="#fff"
          fillColor={ballotProgress.color}
          weight={1}
          smoothFactor={0.9}
          fillOpacity={0.75}
          key={ballotProgress.state}
          positions={ballotProgress.positions}
        >
          <Tooltip>
            <BallotProgressDetails entry={ballotProgress} />
          </Tooltip>
        </Polygon>
      );
    }
    return null;
  });
}
