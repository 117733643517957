import L from 'leaflet';
import React from 'react';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import YangGangCard from '../../components/YangGangCard';
import markerShadow from './marker-shadow.png';
import miniYang from './yang_tiny.gif';

const gangIcon = new L.Icon({
  iconUrl: miniYang,
  iconRetinaUrl: miniYang,
  iconSize: [25, 45],
  iconAnchor: [12, 45],
  shadowUrl: markerShadow,
  shadowSize: [30, 30],
  shadowAnchor: [10, 30],
});

export default function YangGangMarker({ entry, noHover, permanent }) {
  return (
    <Marker icon={gangIcon} position={entry.position}>
      <Popup autoPan={!noHover}>
        <YangGangCard yangGang={entry} />
      </Popup>
      {!noHover && (
        <Tooltip permanent={permanent} direction="bottom">
          {entry.name} YG
        </Tooltip>
      )}
    </Marker>
  );
}
