import React from 'react';
import Link from '@material-ui/core/Link';
const Address = ({ entity }) => {
  if (entity.addressParts.length === 0) {
    return null;
  }
  const inner = (
    <>
      {entity.addressParts.map((x, i) => (
        <div key={i}>{x}</div>
      ))}
    </>
  );
  if (entity.mapUri) {
    return (
      <Link href={entity.mapUri} rel="noopener noreferrer" target="_blank">
        {inner}
      </Link>
    );
  }
  return inner;
};

export default Address;
