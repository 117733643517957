import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import EventDescription from '../EventDescription';

const useStyles = makeStyles(theme => ({
  importantWrap: {
    backgroundColor: theme.palette.gold.paper,
  },
  importantLabel: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.gold.main,
    borderRadius: 2,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.25, 1),
  },
  importantAvatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.gold.main,
  },
  secondary: {
    display: 'block',
    lineHeight: 1.2,
  },
  title: {
    display: 'block',
    lineHeight: 1.2,
  },
  smallAvatar: {
    height: 30,
    width: 30,
  },
  smallAvatarWrap: {
    minWidth: 40,
  },
}));

function EventListItem({ event, asLink, iconSize, ...otherProps }) {
  const classes = useStyles();
  const Icon = event.EventIcon;

  return (
    <>
      <ListItem
        className={clsx({
          [classes.importantWrap]: event.isImportant,
        })}
        alignItems="flex-start"
        {...otherProps}
      >
        <ListItemAvatar
          className={clsx({ [classes.smallAvatarWrap]: iconSize === 'small' })}
        >
          <Avatar
            className={clsx({
              [classes.importantAvatar]: event.isImportant,
              [classes.smallAvatar]: iconSize === 'small',
            })}
          >
            <Icon fontSize={iconSize} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              {event.isImportant && (
                <Typography
                  component="span"
                  className={classes.importantLabel}
                  variant="caption"
                >
                  HIGHEST PRIORITY
                </Typography>
              )}
              <Typography className={classes.secondary} variant="overline">
                {event.eventTitle} Event
              </Typography>
              <Typography
                component="span"
                color="textPrimary"
                className={classes.title}
                gutterBottom
              >
                {event.title}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                className={classes.secondary}
              >
                {event.getTime(true)}
                {event.timeslots.length > 1 && (
                  <> and {event.timeslots.length - 1} more times</>
                )}
              </Typography>
              <Typography
                variant="caption"
                gutterBottom
                color="textSecondary"
                component="span"
                className={classes.secondary}
              >
                {event.addressParts.join(', ')}
              </Typography>

              {event.description && (
                <EventDescription
                  text={event.description}
                  url={asLink ? null : event.url}
                  component="span"
                  className={classes.secondary}
                  gutterBottom
                />
              )}
              {!asLink && (
                <Button
                  component="a"
                  href={event.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="contained"
                  color="secondary"
                >
                  Sign Up
                </Button>
              )}
            </>
          }
        />
      </ListItem>
      <Divider />
    </>
  );
}

EventListItem.defaultProps = {
  asLink: false, // whether this is being displayed as a link
  iconSize: 'default',
};
export default EventListItem;
