import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export default function EmailLink({ email, title, ygName, cc }) {
  return (
    <Typography gutterBottom component="div">
      <Typography variant="caption">{title}</Typography>
      <br />
      <Link
        href={`mailto:${email}?subject=${encodeURIComponent(ygName)}${
          cc ? `&cc=${cc}` : ''
        }`}
      >
        {email}
      </Link>
    </Typography>
  );
}
