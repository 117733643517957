import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import EventDescription from '../EventDescription';

const useStyles = makeStyles(theme => ({
  important: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.gold.main,
  },
  title: {
    display: 'block',
    lineHeight: 1.2,
  },
  importantLabel: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.gold.main,
    borderRadius: 2,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.25, 1),
  },
  secondary: {
    display: 'block',
  },
  description: {
    display: 'block',
  },
  smallAvatar: {
    height: 30,
    width: 30,
  },
  smallAvatarWrap: {
    minWidth: 40,
  },
}));

export default function VirtualEventListItem({ event, iconSize, ...props }) {
  const classes = useStyles();
  const Icon = event.EventIcon;

  return (
    <ListItem alignItems="flex-start" {...props}>
      <ListItemAvatar
        className={clsx({ [classes.smallAvatarWrap]: iconSize === 'small' })}
      >
        <Avatar
          className={clsx({
            [classes.important]: event.isImportant,
            [classes.smallAvatar]: iconSize === 'small',
          })}
        >
          <Icon fontSize={iconSize} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            {event.isImportant && (
              <Typography
                component="span"
                className={classes.importantLabel}
                variant="caption"
              >
                HIGHEST PRIORITY
              </Typography>
            )}
            <Typography className={classes.secondary} variant="overline">
              Virtual {event.eventTitle} Event
            </Typography>
            <Typography
              component="span"
              color="textPrimary"
              className={classes.title}
              gutterBottom
            >
              {event.title}
            </Typography>
          </>
        }
        secondary={
          <>
            <Typography
              variant="body2"
              gutterBottom
              color="textPrimary"
              component="span"
              className={classes.secondary}
            >
              {event.time}
            </Typography>
            {event.description && (
              <EventDescription
                text={event.description}
                url={event.url}
                component="span"
                gutterBottom
                className={classes.description}
              />
            )}
            <Button
              component="a"
              href={event.url}
              rel="noopener noreferrer"
              target="_blank"
              variant="contained"
              color="secondary"
            >
              Join In
            </Button>
          </>
        }
      />
    </ListItem>
  );
}

VirtualEventListItem.defaultProps = {
  iconSize: 'default',
};
