import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import FieldOfficeCard from '../FieldOfficeCard';

function FieldOfficeListItem({
  isDesktop,
  fieldOffice,
  asLink,
  iconSize,
  ...otherProps
}) {
  if (isDesktop) {
    return (
      <>
        <ListItem {...otherProps}>
          <ListItemText
            primary={fieldOffice.title}
            secondary={<>{fieldOffice.addressParts.join(', ')}</>}
          />
        </ListItem>
        <Divider />
      </>
    );
  }

  return (
    <>
      <ListItem alignItems="flex-start" {...otherProps}>
        <FieldOfficeCard fieldOffice={fieldOffice} />
      </ListItem>
      <Divider />
    </>
  );
}

FieldOfficeListItem.defaultProps = {
  asLink: false, // whether this is being displayed as a link
  iconSize: 'default',
};
export default FieldOfficeListItem;
