import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import trimText from 'read-more-react/dist/utils/trimText';

export default function EventDescription({ text, url, ...otherProps }) {
  const [preview, rest] = trimText(text);

  return (
    <Typography variant="body2" color="textSecondary" {...otherProps}>
      {preview}{' '}
      {rest && url && (
        <Link rel="noopener noreferrer" target="_blank" href={url}>
          read more
        </Link>
      )}
    </Typography>
  );
}
