import axios from 'axios';
import React from 'react';
import BallotProgress from '../entities/BallotProgress';
import { BALLOT_PROGRESS_URL } from '../config';
import get from 'lodash/get';

// data source generated from https://docs.google.com/spreadsheets/d/1bmB9x2xY1-xLgR5GdCkTZj2J56YqpHKMTplf29EkdI8/edit#gid=0
export default function useBallotProgress(enabled = true) {
  const [data, setData] = React.useState({ states: [], isLoaded: false });

  React.useEffect(() => {
    if (enabled) {
      async function fetchData() {
        const result = await axios(BALLOT_PROGRESS_URL);

        const { states } = get(result, 'data.values', []).reduce(
          (acc, data) => {
            const state = new BallotProgress(data);
            acc.states.push(state);
            return acc;
          },
          {
            states: [],
          }
        );
        setData({ states, isLoaded: true });
      }
      fetchData();
    }
  }, [enabled, setData]);

  return data;
}
