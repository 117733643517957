import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameMonth from 'date-fns/isSameMonth';
import isSameWeek from 'date-fns/isSameWeek';
import isThisMonth from 'date-fns/isThisMonth';
import isThisWeek from 'date-fns/isThisWeek';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isWeekend from 'date-fns/isWeekend';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import React from 'react';
import { URI_PARAM_DATE } from '../constants/uri';
import persistUri from '../utils/persistUri';

const isNextWeek = date => {
  return isSameWeek(date, addWeeks(new Date(), 1));
};

const isNextMonth = date => {
  return isSameMonth(date, addMonths(new Date(), 1));
};

// this logic may be screwy. writing this code too late in the night.
const isThisWeekend = date => {
  const diff = differenceInCalendarDays(date, new Date());
  if (diff < 7 && isWeekend(date)) {
    // if it's currently a weekend, then only care about this weekend
    if (isWeekend(new Date())) {
      return diff < 3;
    }
    return true;
  }
  return false;
};

const DATE_RANGES = [
  {
    key: 't',
    title: 'Today',
    func: isToday,
  },
  {
    key: 'tm',
    title: 'Tomorrow',
    func: isTomorrow,
  },
  {
    key: 'w',
    title: 'This Week',
    func: isThisWeek,
  },
  {
    key: 'we',
    title: 'This Weekend',
    func: isThisWeekend,
  },
  {
    key: 'nw',
    title: 'Next Week',
    func: isNextWeek,
  },
  {
    key: 'm',
    title: 'This Month',
    func: isThisMonth,
  },
  {
    key: 'nm',
    title: 'Next Month',
    func: isNextMonth,
  },
];

/**
 * given list of items, filter them
 */

export default function useDateFilter(items, initialFilters = []) {
  const [activeDateFilter, rawSetActiveDateFilter] = React.useState(
    initialFilters
  );

  const setActiveDateFilter = React.useCallback(x => {
    persistUri({
      [URI_PARAM_DATE]: x,
    });

    rawSetActiveDateFilter(x);
  }, []);

  return React.useMemo(() => {
    // Loop through all filters and items, generate lists of indexes of all matches
    const filteredItemIndexes = items.reduce((acc, item, idx) => {
      DATE_RANGES.forEach(({ func, key }) => {
        if (item.checkDateFunc && item.checkDateFunc(func)) {
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(idx);
        }
      });
      return acc;
    }, {});

    // track which items should currently be displayed
    let activeItems = items;
    if (items.length > 0 && activeDateFilter.length > 0) {
      const activeItemIds = uniq(
        activeDateFilter.reduce((acc, x) => {
          acc = acc.concat(filteredItemIndexes[x]);
          return acc;
        }, [])
      );
      activeItems = activeItemIds.map(idx => items[idx]);
    }

    // track which ranges can be chosen.
    const eligibleRanges = filter(
      DATE_RANGES,
      ({ key }) => filteredItemIndexes[key]
    );

    const toggleActiveDateFilter = key => {
      const nextFilters = activeDateFilter.includes(key)
        ? without(activeDateFilter, key)
        : [key, ...activeDateFilter];
      setActiveDateFilter(nextFilters);
    };

    // TODO: consider cleaner interface that exposes less internal workings
    return {
      eligibleRanges,
      toggleActiveDateFilter,
      setActiveDateFilter,
      activeDateFilter,
      items: activeItems,
    };
  }, [items, setActiveDateFilter, activeDateFilter]);
}
