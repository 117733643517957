import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import { Router } from '@reach/router';
import clsx from 'clsx';
import qs from 'query-string';
import React from 'react';
import './App.css';
import APIWrapper from './components/APIWrapper';
import ManageScroll from './components/ManageScroll';
import SearchWidget from './components/SearchWidget';
import SideNav from './components/SideNav';
import { URI_PARAM_EMBED, URI_PARAM_MINIMAL } from './constants/uri';
import MapsPage from './pages/MapsPage';
import NotFoundPage from './pages/NotFoundPage';
import VirtualEventsPage from './pages/VirtualEventsPage';
import CalendarEventsPage from './pages/CalendarEventsPage';
import { TOOLBAR_HEIGHT_LG } from './theme';
import { theme } from './theme.js';
export const SIDE_NAV_WIDTH = 240;

const useStyles = makeStyles(theme => ({
  menuButton: {
    margin: 0,
  },
  title: {
    marginRight: 'auto',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '130%',
    letterSpacing: 0.2,
    marginLeft: 0,
    textTransform: 'uppercase',
    '@media (max-width:320px)': { fontSize: '100%' },
  },
  rootDesktop: { display: 'flex' },
  drawerDesktop: {
    width: SIDE_NAV_WIDTH,
    flexShrink: 0,
  },
  drawerPaperDesktop: {
    top: TOOLBAR_HEIGHT_LG,
    width: SIDE_NAV_WIDTH,
  },
  contentDesktop: {
    flexGrow: 1,
  },
  appBarDesktop: {},
  toolbar: theme.mixins.toolbar,
}));

function App() {
  const searchParams = qs.parse(window.location.search);
  const isEmbedded = !!searchParams[URI_PARAM_EMBED];
  const isMinimal = !!searchParams[URI_PARAM_MINIMAL];
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const addlProps = { isDesktop, isEmbedded, isMinimal };

  return (
    <APIWrapper>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ManageScroll />
        <div className={clsx({ [classes.rootDesktop]: isDesktop })}>
          {!isEmbedded && (
            <>
              <AppBar
                position="fixed"
                className={clsx({ [classes.appBarDesktop]: isDesktop })}
              >
                <Toolbar disableGutters>
                  <SearchWidget
                    menuButton={
                      <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerOpen}
                      >
                        <MenuIcon />
                      </IconButton>
                    }
                  />
                </Toolbar>
              </AppBar>
              <SideNav
                theme={theme}
                open={open}
                handleDrawerClose={handleDrawerClose}
              />
            </>
          )}

          <div className={clsx({ [classes.contentDesktop]: isDesktop })}>
            {!isEmbedded && <div className={classes.toolbar} />}
            <Router>
              <NotFoundPage default />
              <VirtualEventsPage path="/virtual" {...addlProps} />
              <CalendarEventsPage path="/calendar" {...addlProps} />
              <MapsPage path="/" {...addlProps} />
              <MapsPage path="/:state" {...addlProps} />
            </Router>
          </div>
        </div>
      </ThemeProvider>
    </APIWrapper>
  );
}

export default App;
