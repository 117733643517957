import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
// import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Title from '../components/Title';

export default function CalendarEventsPage({ location }) {
  'https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=NXZhaDhhNmlvZGV0aTJrbm5wcHFnOWFhY2dAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=NnI4OXM0cGR2YmYwcTRtaDFtcGs0cHBycHNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%234285F4&amp;color=%233F51B5&amp;title=Yang%20Gang%20Events&amp;showNav=1&amp;showTitle=0&amp;showDate=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=1&amp;showTz=1';
  return (
    <Container maxWidth="md">
      <Box py={2}>
        <Box px={2}>
          <Title variant="h5">Calendar Events</Title>
        </Box>
        <List>
          <iframe
            title="calendar-embed"
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=NXZhaDhhNmlvZGV0aTJrbm5wcHFnOWFhY2dAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=NnI4OXM0cGR2YmYwcTRtaDFtcGs0cHBycHNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%234285F4&amp;color=%233F51B5&amp;title=Yang%20Gang%20Events&amp;showNav=1&amp;showTitle=0&amp;showDate=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=1&amp;showTz=1"
            style={{ border: 'solid 1px #777' }}
            width="800"
            height="600"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </List>
      </Box>
      <Box py={3}>
        <Typography variant="body2" gutterBottom></Typography>
      </Box>
    </Container>
  );
}
