import format from 'date-fns/format';
import urlExtractor from '../utils/urlExtractor';
import { TYPE_TITLE_MAP, TYPE_ICON_MAP, convertToDate } from './Event';
/**
 * Class wrapper around GeoJSON for Events returned from Howard's service.
 * Currently only using for virtual events.
 * TODO: resolve Event source
 */
// TODO: improve names
const TYPE_TRAVIS = 't';

export default class VirtualEvent {
  constructor(data, type = TYPE_TRAVIS) {
    const props = data.properties;
    const tType = type === TYPE_TRAVIS;
    this.isVirtual = true;
    this.coordinates = data.geometry.coordinates;
    this.type = tType ? props.event_type : props['Type'];
    this.title = tType ? props.title : props['Title'];
    this.description = tType
      ? props.description
      : props.Description.replace(/<br>/g, ' ');

    if (tType) {
      this.url = props.browser_url;
      this.id = String(props.id).toLowerCase();
    } else {
      this.url = urlExtractor(props['Sign Up']);

      this.id = this.url
        .split('event/')[1]
        .split('/')[0]
        .toLowerCase();
    }

    if (props.timeslots && props.timeslots.length > 0) {
      this.timeStart = convertToDate(props.timeslots[0].start_date);
      this.timeEnd = convertToDate(props.timeslots[0].end_date);
    } else {
      this.timeStart = convertToDate(props['TimeStart']);
      this.timeEnd = convertToDate(props['TimeEnd']);
    }
    this.type = tType ? props.event_type : props['Type'];
    this.signup = props['Sign Up'];
    this.time = tType ? `${this.timeStart}` : props['Time'];
    this.priority = tType ? props.high_priority : props['Priority'];

    this.addressParts = [];
    if (tType && props.location) {
      const address1 = (props.location.address_lines || [])
        .filter(Boolean)
        .join(', ');

      if (address1) {
        this.addressParts.push(address1);
      }
      if (
        props.location.locality &&
        props.location.region &&
        props.location.postal_code
      ) {
        this.addressParts.push(
          `${props.location.locality}, ${props.location.region} ${props.location.postal_code}`
        );
      }
    } else {
      this.addressParts = props.Address.split('<br>');
    }
    this.address = this.addressParts.join(', ')


    this.searchDescription = this.description;
  }

  get eventTitle() {
    return TYPE_TITLE_MAP[this.type] || TYPE_TITLE_MAP.OTHER;
  }

  get EventIcon() {
    return TYPE_ICON_MAP[this.type] || TYPE_ICON_MAP.OTHER;
  }

  get uri() {
    return `/virtual#${this.id}`;
  }

  get dateShort() {
    if (this.timeStart) {
      return format(this.timeStart, 'LLL d');
    }
    return '';
  }

  get isImportant() {
    return this.priority === 'HIGH';
  }
}

/*
Data example:
{
  "type": "FeatureCollection",
  "features": [{
    "type": "Feature",
    "properties": {
        "Priority": "HIGH",
        "Title": "Iowa Phone Bank Training",
        "Type": "TRAINING",
        "Host": "Campaign",
        "Time": "Tue, Dec 3, 7:00PM-8:00PM CST",
        "Venue": "",
        "Address": "Virtual",
        "Sign Up": "<a href=\"https://www.mobilize.us/yang2020/event/154108/\">Mobilize US</a>",
        "Description": "With less than 80 days until caucus, we need all hands on deck. If you live in Iowa and want to make a huge difference,  we need more volunteers. This is one of the best ways you can reach out to your neighbors and move the needle! <br><br>This training is to teach you the basics of Phone Banking and get you in touch with a Staff Organize that can get you an effective list to call to get the candidates name out there! The training lasts for about an hour, just select a time slot that works best for you. On the call, you're able to do a few practice phone calls with an Organizer on the line to help if you get stuck. <br><br>Remember we rely on this grassroots effort that you helped create, but we can't just stop at vocalizing support. We have to put in more effort than any other campaign out there, and this is where it all starts!",
        "TimeStart": 1575421200,
        "TimeEnd": 1575424800,
        "marker-color": "#F2A00F",
        "marker-symbol": "c",
        "marker-size": "small"
    },
    "geometry": {
        "type": "Point",
        "coordinates": [
            -135,
            34
        ]
    }
},, ...]
}*/
