export const URI_PARAM_CENTER = 'c'; // center of map, comma separated
export const URI_PARAM_ZOOM = 'z'; // zoom level
export const URI_PARAM_PAN = 'pan'; // setting this to 0 causes map to not pan
export const URI_PARAM_YANG_GANG = 'yg'; // which specific Yang Gang is shown
export const URI_PARAM_FIELD_OFFICE = 'fo'; // which specific Field Office is visible
export const URI_PARAM_EVENT = 'event'; // what specific event is visible
export const URI_PARAM_DATE = 'd'; // which date filters to have active
export const URI_PARAM_TYPE = 't'; // which event types to show
export const URI_PARAM_EMBED = 'embed'; // whether to show as if it were embedded in an iframe
export const URI_PARAM_MINIMAL = 'min'; // whether to hide search and filters

// map layer type slugs. Event types come from Mobilize and look like 'CANVASSING'
export const URI_TYPE_ANY_EVENT = 'any'; // shows any type of event
export const URI_TYPE_YG = 'yg'; // shows yang gangs
export const URI_TYPE_FO = 'fo'; // shows field offices
export const URI_TYPE_POPUP = 'pop'; // shows popup field offices
export const URI_TYPE_BP = 'bp'; // shows ballot progress

// Default early state types to show
export const EARLY_STATE_DEFAULT_TYPES = [
  URI_TYPE_FO,
  URI_TYPE_POPUP,
  'CANVASS',
  'COMMUNITY',
  'PHONE_BANK',
  'TRAINING',
];
