import { EARLY_STATE_DEFAULT_TYPES } from './uri';

export default [
  {
    name: 'Alabama',
    id: 'AL',
    center: [32.806671, -86.79113],
    territory: false,
  },
  {
    name: 'Alaska',
    id: 'AK',
    center: [61.370716, -152.404419],
    territory: false,
  },
  {
    name: 'American Samoa',
    id: 'AS',
    territory: true,
  },
  {
    name: 'Arizona',
    id: 'AZ',
    center: [33.729759, -111.431221],
    territory: false,
  },
  {
    name: 'Arkansas',
    id: 'AR',
    center: [34.969704, -92.373123],
    territory: false,
  },
  {
    name: 'California',
    id: 'CA',
    center: [36.116203, -119.681564],
    territory: false,
  },
  {
    name: 'Colorado',
    id: 'CO',
    center: [39.059811, -105.311104],
    territory: false,
  },
  {
    name: 'Connecticut',
    id: 'CT',
    center: [41.597782, -72.755371],
    territory: false,
  },
  {
    name: 'Delaware',
    id: 'DE',
    center: [39.318523, -75.507141],
    territory: false,
  },
  {
    name: 'District Of Columbia',
    id: 'DC',
    center: [38.897438, -77.026817],
    territory: false,
  },
  {
    name: 'Federated States Of Micronesia',
    id: 'FM',
    territory: true,
  },
  {
    name: 'Florida',
    id: 'FL',
    center: [27.766279, -81.686783],
    territory: false,
  },
  {
    name: 'Georgia',
    id: 'GA',
    center: [33.040619, -83.643074],
    territory: false,
  },
  {
    name: 'Guam',
    id: 'GU',
    territory: true,
  },
  {
    name: 'Hawaii',
    id: 'HI',
    center: [21.094318, -157.498337],
    territory: false,
  },
  {
    name: 'Idaho',
    id: 'ID',
    center: [44.240459, -114.478828],
    territory: false,
  },
  {
    name: 'Illinois',
    id: 'IL',
    center: [39.987626, -89.169247],
    territory: false,
    zoom: 7,
  },
  {
    name: 'Indiana',
    id: 'IN',
    center: [39.849426, -86.258278],
    territory: false,
  },
  {
    name: 'Iowa',
    id: 'IA',
    center: [42.011539, -93.210526],
    territory: false,
    typeFilters: EARLY_STATE_DEFAULT_TYPES,
    zoom: 7,
  },
  {
    name: 'Kansas',
    id: 'KS',
    center: [38.5266, -96.726486],
    territory: false,
  },
  {
    name: 'Kentucky',
    id: 'KY',
    center: [37.66814, -84.670067],
    territory: false,
  },
  {
    name: 'Louisiana',
    id: 'LA',
    center: [31.169546, -91.867805],
    territory: false,
  },
  {
    name: 'Maine',
    id: 'ME',
    center: [44.693947, -69.381927],
    territory: false,
  },
  {
    name: 'Marshall Islands',
    id: 'MH',
    territory: true,
  },
  {
    name: 'Maryland',
    id: 'MD',
    center: [39.063946, -76.802101],
    territory: false,
  },
  {
    name: 'Massachusetts',
    id: 'MA',
    center: [42.230171, -71.530106],
    territory: false,
  },
  {
    name: 'Michigan',
    id: 'MI',
    center: [43.326618, -84.536095],
    territory: false,
  },
  {
    name: 'Minnesota',
    id: 'MN',
    center: [45.694454, -93.900192],
    territory: false,
  },
  {
    name: 'Mississippi',
    id: 'MS',
    center: [32.741646, -89.678696],
    territory: false,
  },
  {
    name: 'Missouri',
    id: 'MO',
    center: [38.456085, -92.288368],
    territory: false,
  },
  {
    name: 'Montana',
    id: 'MT',
    center: [46.921925, -110.454353],
    territory: false,
  },
  {
    name: 'Nebraska',
    id: 'NE',
    center: [41.12537, -98.268082],
    territory: false,
  },
  {
    name: 'Nevada',
    id: 'NV',
    center: [38.313515, -117.055374],
    territory: false,
    typeFilters: EARLY_STATE_DEFAULT_TYPES,
  },
  {
    name: 'New Hampshire',
    id: 'NH',
    center: [43.452492, -71.563896],
    territory: false,
    typeFilters: EARLY_STATE_DEFAULT_TYPES,
    zoom: 7,
  },
  {
    name: 'New Jersey',
    id: 'NJ',
    center: [40.298904, -74.521011],
    territory: false,
  },
  {
    name: 'New Mexico',
    id: 'NM',
    center: [34.840515, -106.248482],
    territory: false,
  },
  {
    name: 'New York',
    id: 'NY',
    center: [42.165726, -74.948051],
    territory: false,
  },
  {
    name: 'North Carolina',
    id: 'NC',
    center: [35.630066, -79.806419],
    territory: false,
  },
  {
    name: 'North Dakota',
    id: 'ND',
    center: [47.528912, -99.784012],
    territory: false,
  },
  {
    name: 'Northern Mariana Islands',
    id: 'MP',
    territory: true,
  },
  {
    name: 'Ohio',
    id: 'OH',
    center: [40.388783, -82.764915],
    territory: false,
  },
  {
    name: 'Oklahoma',
    id: 'OK',
    center: [35.565342, -96.928917],
    territory: false,
  },
  {
    name: 'Oregon',
    id: 'OR',
    center: [44.572021, -122.070938],
    territory: false,
  },
  {
    name: 'Palau',
    id: 'PW',
    territory: true,
  },
  {
    name: 'Pennsylvania',
    id: 'PA',
    center: [40.590752, -77.209755],
    territory: false,
  },
  {
    name: 'Puerto Rico',
    id: 'PR',
    territory: true,
  },
  {
    name: 'Rhode Island',
    id: 'RI',
    center: [41.680893, -71.51178],
    territory: false,
  },
  {
    name: 'South Carolina',
    id: 'SC',
    center: [33.856892, -80.945007],
    territory: false,
    typeFilters: EARLY_STATE_DEFAULT_TYPES,
    zoom: 7,
  },
  {
    name: 'South Dakota',
    id: 'SD',
    center: [44.299782, -99.438828],
    territory: false,
  },
  {
    name: 'Tennessee',
    id: 'TN',
    center: [35.747845, -86.692345],
    territory: false,
  },
  {
    name: 'Texas',
    id: 'TX',
    center: [31.054487, -97.563461],
    territory: false,
  },
  {
    name: 'Utah',
    id: 'UT',
    center: [40.150032, -111.862434],
    territory: false,
  },
  {
    name: 'Vermont',
    id: 'VT',
    center: [44.045876, -72.710686],
    territory: false,
  },
  {
    name: 'Virgin Islands',
    id: 'VI',
    territory: true,
  },
  {
    name: 'Virginia',
    id: 'VA',
    center: [37.769337, -78.169968],
    territory: false,
  },
  {
    name: 'Washington',
    id: 'WA',
    center: [47.400902, -121.490494],
    territory: false,
  },
  {
    name: 'West Virginia',
    id: 'WV',
    center: [38.491226, -80.954453],
    territory: false,
  },
  {
    name: 'Wisconsin',
    id: 'WI',
    center: [44.268543, -89.616508],
    territory: false,
  },
  {
    name: 'Wyoming',
    id: 'WY',
    center: [42.755966, -107.30249],
    territory: false,
  },
];
