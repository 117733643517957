import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import BallotProgressDetails from '../BallotProgressDetails';

function BallotProgressListItem({ entry, asLink, iconSize, ...otherProps }) {
  return (
    <>
      <ListItem alignItems="flex-start" {...otherProps}>
        <div>
          <BallotProgressDetails entry={entry} />
        </div>
      </ListItem>
      <Divider />
    </>
  );
}

export default BallotProgressListItem;
