import L from 'leaflet';
import React from 'react';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import FieldOfficeCard from '../../components/FieldOfficeCard';
import markerShadow from './marker-shadow.png';
import flag from './flag.png';
import flag2x from './flag@2x.png';
import flagPriority from './flag-priority.png';
import flagPriority2x from './flag-priority@2x.png';

export { flagPriority2x, flag2x };

const flagIcon = new L.Icon({
  iconUrl: flag,
  iconRetinaUrl: flag2x,
  iconSize: [22, 40],
  iconAnchor: [1, 40],
  shadowUrl: markerShadow,
  shadowSize: [30, 30],
  shadowAnchor: [10, 30],
});
const flagPriorityIcon = new L.Icon({
  iconUrl: flagPriority,
  iconRetinaUrl: flagPriority2x,
  iconSize: [22, 40],
  iconAnchor: [1, 40],
  shadowUrl: markerShadow,
  shadowSize: [30, 30],
  shadowAnchor: [10, 30],
});

export default function FieldOfficeMarker({ entry, noHover, permanent }) {
  return (
    <Marker
      key={permanent} // HACK to force refresh
      icon={entry.isImportant ? flagPriorityIcon : flagIcon}
      position={entry.position}
    >
      <Popup autoPan={!noHover}>
        <FieldOfficeCard fieldOffice={entry} />
      </Popup>
      {!noHover && (
        <Tooltip permanent={permanent} direction="bottom">
          {entry.name} {entry.isPopup ? 'Pop-up' : 'FO'}
        </Tooltip>
      )}
    </Marker>
  );
}
