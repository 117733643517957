import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import without from 'lodash/without';
import React from 'react';
import {
  URI_PARAM_TYPE,
  URI_TYPE_ANY_EVENT,
  URI_TYPE_FO,
  URI_TYPE_YG,
} from '../constants/uri';
import { TYPE_ICON_MAP, TYPE_TITLE_MAP } from '../entities/Event';
import persistUri from '../utils/persistUri';
const validTypes = Object.keys(TYPE_TITLE_MAP);

const TOP_LEVEL_TYPES = [URI_TYPE_ANY_EVENT, URI_TYPE_FO, URI_TYPE_YG];

/**
 * Track what event type filters are currently being applied on the map
 * @param {*} events
 */
export default function useEventTypeFilter(events, initialTypeFilters = []) {
  // multiple filters can be applied. Filters are applied as OR
  const [activeEventTypeFilter, rawSetActiveEventTypeFilter] = React.useState(
    initialTypeFilters
  );

  const setActiveEventTypeFilter = React.useCallback(x => {
    persistUri({
      [URI_PARAM_TYPE]: x,
    });

    rawSetActiveEventTypeFilter(x);
  }, []);

  return React.useMemo(() => {
    const countMap = events.reduce((acc, event) => {
      if (validTypes.includes(event.type)) {
        acc[event.type] = (acc[event.type] || 0) + 1;
      }
      return acc;
    }, {});

    const typesWithCount = Object.keys(countMap).map(key => ({
      key,
      text: TYPE_TITLE_MAP[key],
      Icon: TYPE_ICON_MAP[key],
      count: countMap[key],
    }));
    const types = sortBy(typesWithCount, x => x.text);
    const allEvents = activeEventTypeFilter.includes(URI_TYPE_ANY_EVENT);
    const items =
      activeEventTypeFilter.length > 0 && !allEvents
        ? filter(events, x => activeEventTypeFilter.includes(x.type))
        : events;

    const toggleActiveEventTypeFilter = key => {
      const currentlyActive = activeEventTypeFilter.includes(key);
      let nextFilters = currentlyActive
        ? without(activeEventTypeFilter, key)
        : [key, ...activeEventTypeFilter];

      if (!currentlyActive) {
        if (!TOP_LEVEL_TYPES.includes(key)) {
          nextFilters = without(nextFilters, URI_TYPE_ANY_EVENT);
        } else if (key === URI_TYPE_ANY_EVENT) {
          // clear any other events
          nextFilters = nextFilters.filter(x => TOP_LEVEL_TYPES.includes(x));
        }
      }

      setActiveEventTypeFilter(nextFilters);
    };

    return {
      types,
      items,
      toggleActiveEventTypeFilter,
      activeEventTypeFilter,
      setActiveEventTypeFilter,
    };
  }, [activeEventTypeFilter, events, setActiveEventTypeFilter]);
}
