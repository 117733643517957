import Box from '@material-ui/core/Box';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles(theme => ({
  top: {
    background: theme.palette.blueLight.main,
    color: theme.palette.blueLight.contrastText,
  },
  body: {
    background: theme.palette.gold.paper,
  },
}));

export default function StateSteps({ children, className, isDesktop, title }) {
  const classes = useStyles();
  return (
    <div className={className}>
      <Box p={1} className={classes.top}>
        <Typography variant={isDesktop ? 'h6' : 'subtitle2'}>
          {title}
        </Typography>
      </Box>
      <Box className={classes.body} pl={1}>
        {children}
      </Box>
    </div>
  );
}
