import React from 'react';
// whether to highlight a bit of text when

export default function PriorityHighlight({ children, priority }) {
  if (priority) {
    return (
      <span
        style={{
          backgroundColor: '#ff0',
        }}
      >
        {children}
      </span>
    );
  }
  return children;
}
