import L from 'leaflet';
import React from 'react';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import trimText from 'read-more-react/dist/utils/trimText';
import EventCard from '../../components/EventCard';
import markerShadow from './marker-shadow.png';
import pinBlueLightSm from './pin-blue-light-sm.png';
import pinBlueLightSm2x from './pin-blue-light-sm@2x.png';
import pinGoldSm from './pin-gold-sm.png';
import pinGoldSm2x from './pin-gold-sm@2x.png';

const pinBlueLightSmIcon = new L.Icon({
  iconUrl: pinBlueLightSm,
  iconRetinaUrl: pinBlueLightSm2x,
  iconSize: [21, 29],
  iconAnchor: [10, 27],
  shadowUrl: markerShadow,
  shadowSize: [41, 41],
  shadowAnchor: [13, 39],
});

const pinGoldSmIcon = new L.Icon({
  iconUrl: pinGoldSm,
  iconRetinaUrl: pinGoldSm2x,
  iconSize: [21, 29],
  iconAnchor: [10, 27],
  shadowUrl: markerShadow,
  shadowSize: [41, 41],
  shadowAnchor: [13, 39],
});

function shorten(text) {
  const parts = trimText(text, 20, 30, 40);
  return `${parts[0]}${parts[1] ? '…' : ''}`;
}

export default function EventMarker({ entry, noHover }) {
  return (
    <Marker
      icon={entry.isImportant ? pinGoldSmIcon : pinBlueLightSmIcon}
      position={entry.position}
    >
      <Popup autoPan={!noHover}>
        <EventCard event={entry} />
      </Popup>
      {!noHover && (
        <Tooltip>
          <b>{entry.dateShort}</b> {shorten(entry.title)}
        </Tooltip>
      )}
    </Marker>
  );
}
