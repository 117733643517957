import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import APIWrapperContext from './APIWrapper/APIWrapperContext';
import SearchForm from './SearchForm';

const fuseOptions = {
  tokenize: true,
  minMatchCharLength: 2,
  shouldSort: true,
  keys: [
    {
      name: 'title',
      weight: 0.1,
    },
    {
      name: 'searchDescription',
      weight: 1,
    },
  ],
};

export default function SearchWidget({ menuButton, className }) {
  const { events, yangGangs, fieldOffices } = React.useContext(
    APIWrapperContext
  );

  const isLarge = useMediaQuery('(min-width:600px)');
  const autocompleteRef = React.useRef();
  const [fuse, setFuse] = useState(null);

  useEffect(() => {
    const instance = new Fuse(
      [...events, ...yangGangs, ...fieldOffices],
      fuseOptions
    );
    window.fuse = instance;
    setFuse(instance);
  }, [events, fieldOffices, yangGangs]);

  const form = (
    <SearchForm
      fuse={fuse}
      className={className}
      shrinkable={isLarge}
      ref={autocompleteRef}
      menuButton={menuButton}
    />
  );

  return form;
}
