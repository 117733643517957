import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import DateFilterList from '../../components/DateFilterList';
import EventTypeFilterList from '../../components/EventTypeFilterList';
import MapFilter from './MapFilter';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(0, 1),
  },
  reset: {
    marginTop: theme.spacing(1),
  },
  icon: { marginRight: theme.spacing(1) },
}));

export default function MapFilters({
  activeDateFilter,
  activeEventTypeFilter,
  className,
  eligibleEventTypes,
  eligibleRanges,
  isDesktop,
  setActiveDateFilter,
  setActiveEventTypeFilter,
  toggleActiveDateFilter,
  toggleActiveEventTypeFilter,
}) {
  const filtersList = [
    {
      title: 'Date',
      selected: activeDateFilter.length > 0,
count: activeDateFilter.length,
      contents: (
        <DateFilterList
          {...{
            activeDateFilter,
            eligibleRanges,
            setActiveDateFilter,
            toggleActiveDateFilter,
          }}
        />
      ),
    },
    {
      title: 'Type',
      selected: activeEventTypeFilter.length > 0,
      count: activeEventTypeFilter.length,
      contents: (
        <EventTypeFilterList
          {...{
            activeEventTypeFilter,
            eligibleEventTypes,
            setActiveEventTypeFilter,
            toggleActiveEventTypeFilter,
          }}
        />
      ),
    },
  ];

  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper, className)}>
      {filtersList.map(({ title, ...props }) => {
        return (
          <MapFilter
            isDesktop={isDesktop}
            key={title}
            title={title}
            {...props}
          />
        );
      })}
    </div>
  );
}
