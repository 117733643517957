import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
  wrapper: {},
  button: {
    margin: theme.spacing(0.5, 1, 0.5, 0),
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none',
    borderRadius: 100,
    paddingRight: theme.spacing(0.25),
  },
  buttonSelected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[300],
  },
  closeWrap: {
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 1,
    textAlign: 'right',
    height: 3,
  },
  close: {
    background: theme.palette.background.paper,
  },
  box: {
    position: 'relative',
    minWidth: 200,
  },
}));

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -12,
    top: 11,
    padding: '0 4px',
  },
}))(Badge);

export default function MapFilter({
  title,
  count,
  selected,
  isDesktop,
  contents,
}) {
  const classes = useStyles();

  const anchorEl = React.useRef();
  const [open, setOpen] = React.useState(false);
  const id = open ? 'simple-popover' : undefined;
  const onClose = () => setOpen(false);
  return (
    <>
      <Button
        ref={anchorEl}
        aria-describedby={id}
        onClick={() => setOpen(x => !x)}
        variant="outlined"
        size="small"
        className={clsx(classes.button, {
          [classes.buttonSelected]: selected,
        })}
      >
        <StyledBadge badgeContent={count} color="secondary">
          {title}
        </StyledBadge>{' '}
        <ArrowDropDownIcon />
      </Button>

      <Popover
        anchorOrigin={{
          vertical: isDesktop ? 'bottom' : 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isDesktop ? 'top' : 'bottom',
          horizontal: 'left',
        }}
        id={id}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl.current}
      >
        <Box p={1} className={classes.box}>
          <div className={classes.closeWrap}>
            <IconButton className={classes.close} onClick={onClose}>
              <CloseIcon title="Close" />
            </IconButton>
          </div>
          {contents}
        </Box>
      </Popover>
    </>
  );
}
