import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { navigate } from '@reach/router';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import APIWrapperContext from './APIWrapper/APIWrapperContext';
const MAX_RESULTS = 30;
const DEBOUNCE_AMOUNT = 500;

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
    width: 400,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 310,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    display: 'block',
  },
  autocompleteRoot: {
    flexGrow: 1,
  },
  thumb: {
    width: theme.spacing(7),
    minHeight: theme.spacing(7),
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    marginRight: theme.spacing(1),
  },
  line: {
    lineHeight: 1.3,
    display: 'block',
  },
}));

const SearchForm = React.forwardRef(function SearchForm(
  { fuse, shrinkable, menuButton, className },
  ref
) {
  const classes = useStyles({ shrinkable });
  const [popupOpen, setPopupOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const { filterObj } = React.useContext(APIWrapperContext);
  const title = get(filterObj, 'searchTitle', 'Search Events & Yang Gangs…');

  const handleChange = event => {
    setInputValue(event.target.value);
  };

  const handleSelect = (_, entry) => {
    if (entry) {
      navigate(entry.getUri(get(filterObj, 'uri')));
      setPopupOpen(false);
    }
  };

  const search = React.useMemo(
    () =>
      debounce(inputValue => {
        if (fuse) {
          const nextOptions = fuse.search(inputValue).slice(0, MAX_RESULTS);
          setOptions(nextOptions);
          // also incorporate google map API?
        }
      }, DEBOUNCE_AMOUNT),
    [setOptions, fuse]
  );

  useEffect(() => {
    search(inputValue);
  }, [inputValue, search]);

  const headerProps = {
    variant: 'body2',
    className: classes.line,
    gutterBottom: true,
  };
  const detailProps = {
    variant: 'caption',
    color: 'textSecondary',
    className: classes.line,
  };

  return (
    <Paper component="form" className={clsx(classes.root, className)}>
      {menuButton}

      <Autocomplete
        ref={ref}
        id="search-form"
        getOptionLabel={option => {
          return option.title || '';
        }}
        classes={{
          root: classes.autocompleteRoot,
        }}
        autoComplete
        clearOnEscape
        disableOpenOnFocus
        filterOptions={x => x}
        includeInputInList
        onChange={handleSelect}
        onClose={() => setPopupOpen(false)}
        onOpen={() => setPopupOpen(true)}
        open={popupOpen}
        options={options}
        renderInput={({ inputProps, InputProps }) => {
          return (
            <InputBase
              className={classes.input}
              inputProps={inputProps}
              inputRef={InputProps.ref}
              placeholder={title}
              aria-label="search"
              onChange={handleChange}
            />
          );
        }}
        renderOption={entry => {
          if (entry.isYangGang) {
            return (
              <div>
                <Typography {...headerProps}>
                  <b>{entry.name}</b> Yang Gang
                </Typography>
                <Typography {...detailProps}>
                  {entry.email || entry.regionalEmail}
                </Typography>
              </div>
            );
          }
          if (entry.isVirtual) {
            return (
              <div>
                <Typography {...headerProps}>
                  <b>{entry.dateShort}</b> {entry.title}
                </Typography>
                <Typography {...detailProps}>Virtual Event</Typography>
              </div>
            );
          }
          return (
            <div>
              <Typography {...headerProps}>
                <b>{entry.dateShort}</b> {entry.title}
              </Typography>
              <Typography {...detailProps}>
                {entry.addressParts.join(' ')}
              </Typography>
            </div>
          );
        }}
      />
    </Paper>
  );
});
export default SearchForm;
