import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import YangGangCard from '../YangGangCard';

function YangGangListItem({
  isDesktop,
  yangGang,
  asLink,
  iconSize,
  ...otherProps
}) {
  if (isDesktop) {
    return (
      <>
        <ListItem {...otherProps}>
          <ListItemText primary={yangGang.title} />
        </ListItem>
        <Divider />
      </>
    );
  }

  return (
    <>
      <ListItem alignItems="flex-start" {...otherProps}>
        <YangGangCard yangGang={yangGang} />
      </ListItem>
      <Divider />
    </>
  );
}

YangGangListItem.defaultProps = {
  asLink: false, // whether this is being displayed as a link
  iconSize: 'default',
};
export default YangGangListItem;
