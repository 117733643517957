import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: 35,
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  mobileListItem: {
    padding: 0,
  },
}));

export default function Step({ title, subtitle, icon, button, isDesktop }) {
  const classes = useStyles();
  return (
    <ListItem
      dense
      disableGutters
      className={clsx({
        [classes.mobileListItem]: !isDesktop,
      })}
    >
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText
        primary={<span className={classes.title}>{title}</span>}
        secondary={subtitle}
      />
      {button && <ListItemSecondaryAction>{button}</ListItemSecondaryAction>}
    </ListItem>
  );
}
