import L from 'leaflet';
import React from 'react';
import slugify from 'slugify';
import YangGangCard from '../components/YangGangCard';
import { URI_PARAM_PAN } from '../constants/uri';
/**
 * Class wrapper around GeoJSON returned from YangGang spreadsheet
 */
export default class YangGang {
  constructor(data) {
    const props = data.properties;
    this.isYangGang = true;
    this.coordinates = data.geometry.coordinates;
    this.id = slugify(
      `${props['Yang_Gang_Name']}-${props['Location']}`
    ).toLowerCase();
    this.name = props['Yang_Gang_Name'];
    this.email = props['Primary_Contact_Email'];
    this.reddit = props['State_Subreddit'];
    this.regionalEmail = props['Regional_Organizer_Email'];
    this.facebook = props['Facebook_Group'];
    this.twitter = props['Twitter'];
    this.website = props['Website'];
    this.instagram = props['Instagram'];
    this.youtube = props['Youtube'];
    this.slack = props['Regional_Slack_Workspace'];
    this.location = props['Location'];

    this.searchDescription = this.email;
  }

  get position() {
    const lat = Number(this.coordinates[1]);
    const lng = Number(this.coordinates[0]);
    if (isFinite(lat) && isFinite(lng)) {
      return L.latLng(lat, lng);
    }
    console.warn('No position on ', this.id);
    return null;
  }

  get title() {
    return `${this.name} Yang Gang`;
  }

  getUri(baseUrl = '/') {
    return `${baseUrl}?yg=${this.id}`;
  }

  getListUri(baseUrl) {
    return `${this.getUri(baseUrl)}&${URI_PARAM_PAN}=0`;
  }

  renderCard() {
    return <YangGangCard yangGang={this} />;
  }
}

/*
Data example:
{
  "type": "FeatureCollection",
  "features": [{
    "type": "Feature",
    "properties": {
        "Yang_Gang_Name": "Birmingham",
        "Primary_Contact_Name": "David Kennell",
        "Location": "Birmingham, Alabama",
        "Primary_Contact_Email": "birmingham.yang.gang@gmail.com",
        "Regional_Organizer_Email": "alabama.yang.gang@gmail.com",
        "Facebook_Group": "https://www.facebook.com/groups/330310791224694/",
        "Twitter": "",
        "Instagram": "",
        "Regional_Slack_Workspace": "",
        "marker-color": "#133467",
        "marker-size": "small",
        "marker-symbol": ""
    },
    "geometry": {
        "type": "Point",
        "coordinates": [
            " -86.8103567",
            "33.51858919999999"
        ]
    }
}, ...]
}*/
