import { createMuiTheme } from '@material-ui/core/styles';

const baseFontFamily = [
  '"Gill Sans"',
  '"Helvetica Neue"',
  'Roboto',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#133567',
    },
    secondary: {
      main: '#DA3248',
    },
    gold: {
      main: '#F2A00F',
      paper: '#FDF4E3',
    },
    blueLight: {
      main: '#6CACE4',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: baseFontFamily,
    fontWeightMedium: 600,
  },
});

export const TOOLBAR_HEIGHT = 56;
export const TOOLBAR_HEIGHT_XS = 48;
export const TOOLBAR_HEIGHT_LG = 64;
