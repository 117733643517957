import { makeStyles } from '@material-ui/core/styles';
import L from 'leaflet';
import React from 'react';
import { Map as LeafletMap, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {
  TILE_LAYER_ATTRIB,
  TILE_LAYER_SIZE,
  TILE_LAYER_URL,
  TILE_LAYER_ZOOM_OFFSET,
  USE_VECTOR_MAP,
  VECTOR_TILE_LAYER_STYLE,
} from '../../config';
import BallotProgressLayers from './BallotProgressLayers';
import LocateButton from './LocateButton';
import MapBoxGLLayer from './MapBoxGLLayer';
import myLocation from './my-location.png';

const myLocationIcon = new L.Icon({
  iconUrl: myLocation,
  iconSize: [22, 22],
  iconAnchor: [11, 11],
});

const useStyles = makeStyles(theme => ({
  myLocation: {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

/**
 *  the actual map displayed to user
 * */

function MapInner({
  noHover,
  markers,
  userPosition,
  activePopup,
  mapRef,
  className,
  viewport,
  onUpdateBounds,
  showBallotProgress,
}) {
  const classes = useStyles();
  const mapElem = mapRef && mapRef.current && mapRef.current.leafletElement;

  const { clustered, unclustered } = markers.reduce(
    (acc, { MarkerComponent, entry, clustered, addlProps }) => {
      if (MarkerComponent) {
        const marker = (
          <MarkerComponent
            noHover={noHover}
            entry={entry}
            key={entry.id}
            {...addlProps}
          />
        );
        if (clustered) {
          acc.clustered.push(marker);
        } else {
          acc.unclustered.push(marker);
        }
      }
      return acc;
    },
    {
      clustered: [],
      unclustered: [],
    }
  );

  return (
    <LeafletMap
      className={className}
      viewport={viewport}
      minZoom={2}
      maxZoom={18}
      zoomSnap={0.5}
      noMoveStart
      ref={mapRef}
      debounceMoveend
      onViewportChanged={onUpdateBounds}
    >
      {USE_VECTOR_MAP === 'true' ? (
        <MapBoxGLLayer
          mapRef={mapRef}
          attribution={TILE_LAYER_ATTRIB}
          accessToken={'not-needed'}
          style={VECTOR_TILE_LAYER_STYLE}
        />
      ) : (
        <TileLayer
          attribution={TILE_LAYER_ATTRIB}
          url={TILE_LAYER_URL}
          tileSize={Number(TILE_LAYER_SIZE)}
          zoomOffset={Number(TILE_LAYER_ZOOM_OFFSET)}
        />
      )}

      {activePopup ? (
        <Popup key={activePopup.id} position={activePopup.position}>
          {activePopup.content}
        </Popup>
      ) : null}

      {userPosition && <Marker position={userPosition} icon={myLocationIcon} />}

      <MarkerClusterGroup maxClusterRadius={20}>{clustered}</MarkerClusterGroup>

      {unclustered}

      <LocateButton mapElem={mapElem} className={classes.myLocation} />

      {showBallotProgress && <BallotProgressLayers />}
    </LeafletMap>
  );
}

export default React.memo(MapInner);
