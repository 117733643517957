import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import L from 'leaflet';
import get from 'lodash/get';
import React from 'react';
import AdapterLink from '../../components/AdapterLink';
import BallotProgressListItem from '../../components/BallotProgressListItem';
import EventListItem from '../../components/EventListItem';
import FieldOfficeListItem from '../../components/FieldOfficeListItem';
import YangGangListItem from '../../components/YangGangListItem';
import VirtualEventListItem from '../VirtualEventListItem';
import markerShadow from './marker-shadow.png';
import pinBlueLightLg from './pin-blue-light-lg.png';
import pinBlueLightLg2x from './pin-blue-light-lg@2x.png';
import pinGoldLg from './pin-gold-lg.png';
import pinGoldLg2x from './pin-gold-lg@2x.png';

const pinBlueLightLgIcon = new L.Icon({
  iconUrl: pinBlueLightLg,
  iconRetinaUrl: pinBlueLightLg2x,
  iconSize: [29, 42],
  iconAnchor: [14, 41],
  shadowUrl: markerShadow,
  shadowSize: [41, 41],
  shadowAnchor: [13, 39],
});
const pinGoldLgIcon = new L.Icon({
  iconUrl: pinGoldLg,
  iconRetinaUrl: pinGoldLg2x,
  iconSize: [29, 42],
  iconAnchor: [14, 41],
  shadowUrl: markerShadow,
  shadowSize: [41, 41],
  shadowAnchor: [13, 39],
});

const MAP_ITEM_LIMIT = 50;

function MapList({
  activeFilter,
  bounds,
  className,
  isDesktop,
  isLoading,
  mapRef,
  markers,
}) {
  const mapElem = mapRef && mapRef.current && mapRef.current.leafletElement;
  const hoverMarker = React.useRef();
  const [visibleMarkers, visibleCount] = React.useMemo(() => {
    const acc = [];
    let visibleCount = 0;
    for (let i = 0; i < markers.length; i++) {
      const x = markers[i];
      if (x.entry.isVirtual) {
        acc.push(x);
      } else if (
        !x.entry.isYangGang &&
        x.entry.position &&
        bounds.contains(x.entry.position)
      ) {
        acc.push(x);
        visibleCount++;
      }
      if (visibleCount > MAP_ITEM_LIMIT + 1) {
        // don't bother getting more
        break;
      }
    }
    return [acc, visibleCount];
  }, [markers, bounds]);

  const clearHoverMarker = React.useCallback(() => {
    if (mapElem && hoverMarker.current) {
      hoverMarker.current.removeFrom(mapElem);
    }
  }, [mapElem]);

  const onHoverMarker = React.useCallback(
    x => {
      if (mapElem) {
        hoverMarker.current = L.marker(
          [
            x.currentTarget.getAttribute('data-entry-lat'),
            x.currentTarget.getAttribute('data-entry-lng'),
          ],
          {
            icon:
              x.currentTarget.getAttribute('data-entry-impt') === 'y'
                ? pinGoldLgIcon
                : pinBlueLightLgIcon,
            zIndexOffset: 1000,
          }
        );
        hoverMarker.current.addTo(mapElem);
      }
    },
    [mapElem]
  );

  let warning = '';

  if (visibleCount === 0) {
    warning = isLoading ? (
      <CircularProgress />
    ) : (
      'No events found. Try moving the map or zooming out.'
    );
  } else if (visibleCount > MAP_ITEM_LIMIT) {
    warning = 'More events were found. Try zooming in or adding a filter.';
  }

  return (
    <Box className={className} pb={6}>
      <List disablePadding>
        {visibleMarkers.slice(0, MAP_ITEM_LIMIT).map(marker => {
          const { entry } = marker;

          if (entry.isVirtual) {
            return (
              <React.Fragment key={entry.id}>
                <VirtualEventListItem iconSize="small" event={entry} />
                <Divider />
              </React.Fragment>
            );
          }

          if (entry.isBallotProgress) {
            return <BallotProgressListItem entry={entry} key={entry.id} />;
          }

          const addlProps = isDesktop
            ? {
                onMouseEnter: onHoverMarker,
                onMouseLeave: clearHoverMarker,
                asLink: true,
                to: entry.getListUri(get(activeFilter, 'uri')),
                component: AdapterLink,
                button: true,
                'data-entry-lat': entry.position.lat,
                'data-entry-lng': entry.position.lng,
                'data-entry-impt': entry.isImportant ? 'y' : 'n',
              }
            : {};

          if (entry.isYangGang) {
            return (
              <YangGangListItem
                isDesktop={isDesktop}
                yangGang={entry}
                key={entry.id}
                {...addlProps}
              />
            );
          }

          if (entry.isFieldOffice) {
            return (
              <FieldOfficeListItem
                isDesktop={isDesktop}
                fieldOffice={entry}
                key={entry.id}
                {...addlProps}
              />
            );
          }

          return (
            <EventListItem
              iconSize="small"
              key={entry.id}
              event={entry}
              {...addlProps}
            />
          );
        })}
      </List>
      <Box p={2}>
        <Typography component="div" align="center">
          {warning}
        </Typography>
      </Box>
    </Box>
  );
}
export default MapList;
