import get from 'lodash/get';
import React from 'react';
import APIWrapperContext from './APIWrapperContext';
import PropTypes from 'prop-types';
import useEvents from '../../hooks/useEvents';
import useYangGangs from '../../hooks/useYangGangs';
import useBallotProgress from '../../hooks/useBallotProgress';
import sortBy from 'lodash/sortBy';

const USE_TRAVIS_EVENTS = true;
const USE_HOWARD_EVENTS = false;

function sortEvents(events) {
  // by default, important first
  // by default, nearest first
  return sortBy(
    events,
    x => (x.isImportant ? 0 : 1),
    x => x.timeStart
  );
}

function processFilter(filter, items) {
  if (filter) {
    return items.filter(x => filter(x));
  }
  return items;
}
/**
 * Load single set of data
 */
function APIWrapper({ children }) {
  const [filterObj, setFilterObj] = React.useState(null);

  const updateFilterObj = React.useCallback(
    nextFilterObj => {
      if (nextFilterObj) {
        if (!filterObj || nextFilterObj.key !== filterObj.key) {
          setFilterObj(nextFilterObj);
        }
      } else if (filterObj) {
        setFilterObj(null);
      }
    },
    [filterObj, setFilterObj]
  );

  const { events, isLoaded: isEventsLoaded } = useEvents(USE_TRAVIS_EVENTS);
  const {
    yangGangs: rawYangGangs,
    events: howardEvents,
    fieldOffices: rawFieldOffices,
    isLoaded: isYGLoaded,
  } = useYangGangs();

  const { states: ballotProgressStates } = useBallotProgress();

  // memo the entire context

  const context = React.useMemo(() => {
    const combinedEvents = [...events];
    const existingIds = events.map(x => x.id);
    const newIds = [];
    // combine Howard events with Travis events
    if (USE_HOWARD_EVENTS) {
      howardEvents.forEach(x => {
        if (!existingIds.includes(x.id)) {
          newIds.push(x.id);
          combinedEvents.push(x);
        }
      });
    }
    console.log(`added ${newIds.length}`);

    const filter = get(filterObj, 'filter');

    const filteredEvents = processFilter(filter, combinedEvents);
    const yangGangs = processFilter(filter, rawYangGangs);
    const fieldOffices = processFilter(filter, rawFieldOffices);

    return {
      ballotProgressStates,
      filterObj,
      updateFilterObj,
      events: sortEvents(filteredEvents),
      fieldOffices,
      isLoaded: isEventsLoaded && isYGLoaded,
      yangGangs,
    };
  }, [
    ballotProgressStates,
    events,
    howardEvents,
    filterObj,
    rawYangGangs,
    rawFieldOffices,
    isEventsLoaded,
    isYGLoaded,
    updateFilterObj,
  ]);
  return (
    <APIWrapperContext.Provider value={context}>
      {children}
    </APIWrapperContext.Provider>
  );
}

APIWrapper.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
};

export default APIWrapper;
