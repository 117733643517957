import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import HowToVoteIcon from '@material-ui/icons/HowToVoteOutlined';
import React from 'react';
import { flagPriority2x } from '../Map/FieldOfficeMarker';
import StateSteps from './StateSteps';
import Step from './Step';
import differenceInHours from 'date-fns/differenceInHours';

const useStyles = makeStyles(theme => ({
  flag: {
    margin: theme.spacing(0, 0.5),
  },
}));

const SC_DATE = new Date('2020/02/29');

function pluralize(number, str) {
  return `${number} ${str}${number === 1 ? '' : 's'}`;
}

export default function SouthCarolinaSteps({ className, isDesktop }) {
  const classes = useStyles();
  const hourDiff = differenceInHours(SC_DATE, new Date());
  let diff = `${pluralize(Math.floor(hourDiff / 24), 'day')}, ${pluralize(
    hourDiff % 24,
    'hour'
  )}`;
  return (
    <StateSteps
      isDesktop={isDesktop}
      className={className}
      title="YANG SOUTH CAROLINA"
    >
      <List disablePadding={!isDesktop}>
        <Step
          isDesktop={isDesktop}
          icon={<AssignmentIcon color="primary" />}
          title="Read the game plan"
          subtitle="5 minute read"
          button={
            <Button
              variant="contained"
              color="secondary"
              component="a"
              rel="noopener noreferrer"
              target="_blank"
              href="https://docs.google.com/document/d/1IpIuLYS35-FoOb-r6scdHRAjZtdL9mTikME_s7Ze56I/"
            >
              SC Plan
            </Button>
          }
        />
        <Step
          isDesktop={isDesktop}
          icon={
            <img
              className={classes.flag}
              src={flagPriority2x}
              height={30}
              alt="Yellow Flag"
            />
          }
          title="Get trained at a top priority field office"
          subtitle="Look for the yellow flags"
        />
        <Step
          isDesktop={isDesktop}
          icon={<HowToVoteIcon color="primary" />}
          title="Win the South Carolina vote"
          subtitle={`Starting in ${diff}`}
        />
      </List>
    </StateSteps>
  );
}
