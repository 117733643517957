import useMediaQuery from '@material-ui/core/useMediaQuery';
import L from 'leaflet';
import React from 'react';
import sortBy from 'lodash/sortBy';
import {
  URI_PARAM_CENTER,
  URI_PARAM_EVENT,
  URI_PARAM_FIELD_OFFICE,
  URI_PARAM_YANG_GANG,
  URI_PARAM_ZOOM,
  URI_TYPE_BP,
  URI_TYPE_FO,
  URI_TYPE_POPUP,
  URI_TYPE_YG,
} from '../../constants/uri';
import useDateFilter from '../../hooks/useDateFilter';
import useEventTypeFilter from '../../hooks/useEventTypeFilter';
import isSubset from '../../utils/isSubset';
import persistUri from '../../utils/persistUri';
import APIWrapperContext from '../APIWrapper/APIWrapperContext';
import { IowaSteps, NHSteps, NVSteps, SCSteps } from '../StateSteps';
import EventMarker from './EventMarker';
import FieldOfficeMarker from './FieldOfficeMarker';
import MapLayout from './MapLayout';
import YangGangMarker from './YangGangMarker';

export const INITIAL_ZOOM = 4;

function encodeCenter(center) {
  // https://xkcd.com/2170/
  return center.map(x => Number.parseFloat(x).toFixed(3)).join(',');
}

const INITIAL_BOUNDS = L.latLngBounds([17.9, -136.3], [54.4, -59.6]);

const US_BOUNDS = L.latLngBounds(
  L.latLng(49.3457868, -124.7844079),
  L.latLng(24.7433195, -66.9513812)
);

const EVENT_SPECIFIC_ZOOM = 12;

export default function Map({
  activeEntitySlug,
  activeEntityType,
  activeFilter,
  className,
  initialCenter,
  initialDateFilters,
  initialTypeFilters,
  initialZoom,
  isDesktop,
  isEmbedded,
  isMinimal,
  pan,
  shouldFit,
}) {
  const StateSteps =
    !isEmbedded && activeFilter
      ? { ia: IowaSteps, nh: NHSteps, nv: NVSteps, sc: SCSteps }[
          activeFilter.id
        ]
      : null;

  const updateBoundsCount = React.useRef(0);

  // TODO: default to appropriate viewport for window size
  const [viewport, setViewport] = React.useState({
    center: initialCenter,
    zoom: initialZoom,
  });
  const [currentZoom, setCurrentZoom] = React.useState(initialZoom);
  const [viewportBounds, setViewportBounds] = React.useState(INITIAL_BOUNDS);
  const [userPosition, setUserPosition] = React.useState(null);
  const [activePopup, setActivePopup] = React.useState(null);
  const [didFit, setDidFit] = React.useState(false);

  // are we in mobile and is an overlay active?
  const [mobileOverlayMode, setMobileOverlayMode] = React.useState(null);
  const hideMobileOverlay = () => setMobileOverlayMode(null);

  // hover related
  const noHover = useMediaQuery('(hover: none)');

  const {
    ballotProgressStates,
    events,
    yangGangs,
    fieldOffices,
    isLoaded,
  } = React.useContext(APIWrapperContext);

  // delay entry of mobile overlay
  React.useEffect(() => {
    setTimeout(() => setMobileOverlayMode(StateSteps ? 'steps' : null), 500);
  }, [StateSteps]);

  // show specific popup given URL
  React.useEffect(() => {
    if (
      activeEntityType &&
      activeEntitySlug &&
      (!activePopup || activePopup.id !== activeEntitySlug)
    ) {
      // attempt to find the entity
      const collection = {
        [URI_PARAM_YANG_GANG]: yangGangs,
        [URI_PARAM_EVENT]: events,
        [URI_PARAM_FIELD_OFFICE]: fieldOffices,
      }[activeEntityType];

      const entity = collection
        ? collection.find(x => x.id === activeEntitySlug)
        : null;

      if (entity && entity.position) {
        setMobileOverlayMode(false);
        if (pan !== '0') {
          setViewport({
            center: entity.position,
            zoom: EVENT_SPECIFIC_ZOOM,
          });
        }

        setActivePopup({
          id: entity.id,
          position: entity.position,
          content: entity.renderCard(),
        });
      }
    }
  }, [
    yangGangs,
    activePopup,
    setActivePopup,
    activeEntityType,
    activeEntitySlug,
    events,
    fieldOffices,
    pan,
  ]);

  const {
    items: dateFilteredEvents,
    activeDateFilter,
    eligibleRanges,
    setActiveDateFilter,
    toggleActiveDateFilter,
  } = useDateFilter(events, initialDateFilters);

  const {
    types: eligibleEventTypes,
    items: filteredEvents,
    activeEventTypeFilter,
    setActiveEventTypeFilter,
    toggleActiveEventTypeFilter,
  } = useEventTypeFilter(dateFilteredEvents, initialTypeFilters);

  const mapRef = React.useRef();
  const mapElem = mapRef && mapRef.current && mapRef.current.leafletElement;
  const showBallotProgress = activeEventTypeFilter.includes(URI_TYPE_BP);

  React.useEffect(() => {
    // listen for map setting user location
    if (mapElem) {
      function locationfound(e) {
        setUserPosition(e.latlng);
      }
      mapElem.on('locationfound', locationfound);
      return () => {
        mapElem.off('locationfound', locationfound);
      };
    }
  }, [mapElem, setUserPosition]);

  const onUpdateBounds = React.useCallback(
    // respond to map movement
    e => {
      if (e) {
        const { center, zoom } = e;
        if (center && zoom) {
          if (updateBoundsCount.current > 0) {
            // HACK: only persist after first event since there doesn't appear to
            // be a better way to filter whether user interaction caused bound update
            persistUri({
              [URI_PARAM_CENTER]: encodeCenter(center),
              [URI_PARAM_ZOOM]: zoom,
            });
          }
          updateBoundsCount.current += 1;
          setCurrentZoom(zoom);
        }
      }
      const _mapElem =
        mapRef && mapRef.current && mapRef.current.leafletElement;
      if (_mapElem) {
        const nextBounds = _mapElem.getBounds();
        setViewportBounds(lastBounds =>
          nextBounds.equals(lastBounds) ? lastBounds : nextBounds
        );
      }
    },
    []
  );

  // process markers into what should be shown etc
  const markers = React.useMemo(() => {
    const showEverything = activeEventTypeFilter.length === 0;
    const showGangs =
      showEverything || activeEventTypeFilter.includes(URI_TYPE_YG);
    const showFieldOffices =
      showEverything || activeEventTypeFilter.includes(URI_TYPE_FO);
    const showPopupFieldOffices =
      showEverything || activeEventTypeFilter.includes(URI_TYPE_POPUP);

    const allMarkers = [];

    filteredEvents.forEach(event => {
      if (event.position) {
        allMarkers.push({
          MarkerComponent: EventMarker,
          entry: event,
          clustered: !event.isImportant,
        });
      } else if (event.isVirtual) {
        allMarkers.push({
          entry: event,
        });
      }
    });

    if (showGangs) {
      yangGangs.forEach(yangGang => {
        if (yangGang.position) {
          allMarkers.push({
            MarkerComponent: YangGangMarker,
            entry: yangGang,
            clustered: true,
          });
        }
      });
    }

    if (showFieldOffices || showPopupFieldOffices) {
      const onlyEitherFieldOffices = isSubset(activeEventTypeFilter, [
        URI_TYPE_FO,
        URI_TYPE_POPUP,
      ]);
      const onlyPopupFieldOffices = isSubset(activeEventTypeFilter, [
        URI_TYPE_POPUP,
      ]);

      fieldOffices.forEach(fo => {
        const shouldShow =
          (showFieldOffices && !fo.isPopup) ||
          (showPopupFieldOffices && fo.isPopup);
        if (shouldShow && fo.position) {
          const addlProps = {};
          // only show label if past a certain zoom and either both popups and
          // regular are shown or only regular are shown
          if (onlyEitherFieldOffices && currentZoom >= 7) {
            let permanentLabel = false;
            if (fo.isPopup && onlyPopupFieldOffices) {
              permanentLabel = true;
            } else if (!fo.isPopup) {
              permanentLabel = true;
            }
            if (permanentLabel) {
              addlProps.permanent = true;
            }
          }
          allMarkers.push({
            MarkerComponent: FieldOfficeMarker,
            entry: fo,
            clustered: !activeFilter,
            addlProps,
          });
        }
      });
    }

    if (showBallotProgress) {
      // push individual states in as markers
      sortBy(ballotProgressStates, x => x.listOrder).forEach(state => {
        if (state.shouldDisplayInList) {
          allMarkers.push({
            entry: state,
          });
        }
      });
    }

    return allMarkers;
  }, [
    activeEventTypeFilter,
    showBallotProgress,
    filteredEvents,
    ballotProgressStates,
    yangGangs,
    currentZoom,
    fieldOffices,
    activeFilter,
  ]);

  React.useEffect(() => {
    // set bounds when map first loads
    if (mapElem) {
      onUpdateBounds();
    }

    // When finished loading events, adjust to appropriately fit the contents.
    if (isLoaded && shouldFit && !didFit) {
      const points = [];
      markers.forEach(marker => {
        const { entry } = marker;
        if (entry.coordinates) {
          const point = L.latLng([
            parseFloat(entry.coordinates[1]),
            parseFloat(entry.coordinates[0]),
          ]);
          if (US_BOUNDS.contains(point)) {
            points.push(point);
          }
        }
      });
      // if no points, don't fly
      if (points.length > 0) {
        mapRef.current.leafletElement.flyToBounds(L.latLngBounds(points), {
          padding: isDesktop ? [50, 50] : [30, 10],
        });
      }
      setDidFit(true);
    }
  }, [
    didFit,
    isDesktop,
    isLoaded,
    mapElem,
    markers,
    onUpdateBounds,
    shouldFit,
  ]);

  return (
    <MapLayout
      isLoading={!isLoaded}
      showBallotProgress={showBallotProgress}
      {...{
        activeFilter,
        activeDateFilter,
        activeEventTypeFilter,
        activePopup,
        className,
        eligibleEventTypes,
        eligibleRanges,
        hideMobileOverlay,
        isDesktop,
        isEmbedded,
        isMinimal,
        mapRef,
        markers,
        mobileOverlayMode,
        noHover,
        onUpdateBounds,
        setActiveDateFilter,
        setActiveEventTypeFilter,
        setMobileOverlayMode,
        StateSteps,
        toggleActiveDateFilter,
        toggleActiveEventTypeFilter,
        userPosition,
        viewport,
        viewportBounds,
      }}
    />
  );
}
