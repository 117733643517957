import L from 'leaflet';
import 'mapbox-gl';
import 'mapbox-gl-leaflet';
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';

export default function MapBoxGLLayer({ mapRef, ...otherProps }) {
  const glRef = React.useRef();
  React.useEffect(() => {
    const mapElem = mapRef && mapRef.current && mapRef.current.leafletElement;
    if (mapElem) {
      if (!glRef.current || !mapElem.hasLayer(glRef.current)) {
        glRef.current = L.mapboxGL(otherProps).addTo(mapElem);
      }
    }
  }, [mapRef, otherProps]);

  return null;
}
