import axios from 'axios';
import React from 'react';
import Event from '../entities/Event';
import VirtualEvent from '../entities/VirtualEvent';
import { EVENTS_URL } from '../config';
import get from 'lodash/get';

export default function useEvents(enabled) {
  const [data, setData] = React.useState({ events: [], isLoaded: !enabled });

  React.useEffect(() => {
    if (enabled) {
      async function fetchData() {
        const result = await axios(EVENTS_URL);
        // HACK: manually exclude duplicated ids
        const { events } = get(result, 'data.features', []).reduce(
          (acc, data) => {
            const event = get(data, 'properties.is_virtual') ? new VirtualEvent(data): new Event(data);
            if (!event.inPast) {
              const id = event.id;
              if (!acc.eventIds.includes(id)) {
                acc.events.push(event);
                acc.eventIds.push(id);
              }
            }
            return acc;
          },
          {
            events: [],
            eventIds: [],
          }
        );
        setData({ events, isLoaded: true });
      }
      fetchData();
    }
  }, [enabled, setData]);

  return data;
}
