import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import Box from '@material-ui/core/Box';
import { TOOLBAR_HEIGHT } from '../../theme';
import MapBottomBar from './MapBottomBar';
import MapFilters from './MapFilters';
import MapInner from './MapInner';
import MapList from './MapList';
import SearchWidget from '../SearchWidget';

const FILTER_BAR_HEIGHT = 40;
const MAP_LIST_WIDTH = 320;
const MOBILE_TOPNAV_HEIGHT = TOOLBAR_HEIGHT;

const useStyles = makeStyles(theme => ({
  desktopSide: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    width: MAP_LIST_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    zIndex: 500, // leaflet map is at 400
  },
  wrapper: {
    position: 'relative',
  },
  map: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    right: 0,
    width: `calc(100vw - ${MAP_LIST_WIDTH}px)`,
  },
  mobileMap: {
    position: 'fixed',
    width: '100vw',
    top: MOBILE_TOPNAV_HEIGHT + FILTER_BAR_HEIGHT,
    bottom: FILTER_BAR_HEIGHT,
  },
  mobileMapMin: { top: 0 },
  filterBar: {
    backgroundColor: theme.palette.background.paper,
    height: FILTER_BAR_HEIGHT,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  mobileFilterBar: {
    left: 0,
    position: 'fixed',
    top: MOBILE_TOPNAV_HEIGHT,
    width: '100vw',
    zIndex: theme.zIndex.appBar + 1,
  },
  mapBottomBar: {
    height: FILTER_BAR_HEIGHT,
    background: theme.palette.grey[200],
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: theme.shadows[3],
    zIndex: theme.zIndex.appBar + 3,
  },
  mapList: {
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.paper,
  },
  mapListDesktop: {
    flexGrow: 1,
  },
  mapListMobile: {
    position: 'fixed',
    top: '100vh',
    height: `calc(100vh - ${FILTER_BAR_HEIGHT * 2 + MOBILE_TOPNAV_HEIGHT}px)`,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar + 2,
    transition: 'top .25s',
    // boxShadow: theme.shadows[7],
  },
  mapListMobileIn: {
    top: FILTER_BAR_HEIGHT + MOBILE_TOPNAV_HEIGHT,
  },
  mobileStateSteps: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    paddingBottom: FILTER_BAR_HEIGHT,
    zIndex: theme.zIndex.appBar + 2,
    transition: 'transform .25s',
    transform: 'translateY(100vh)',
  },
  mobileStateStepsIn: {
    transform: 'translateY(0)',
    boxShadow: theme.shadows[5],
  },
  desktopStateSteps: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  searchWrap: {
    height: 50,
    background: theme.palette.blueLight.main,
  },
  searchWidget: {
    width: 305,
  },
}));
export default function MapLayout({
  isLoading,
  activeFilter,
  activeDateFilter,
  activeEventTypeFilter,
  activePopup,
  className,
  eligibleEventTypes,
  eligibleRanges,
  hideMobileOverlay,
  isDesktop,
  isEmbedded,
  isMinimal,
  mapRef,
  markers,
  mobileOverlayMode,
  noHover,
  onUpdateBounds,
  setActiveDateFilter,
  setActiveEventTypeFilter,
  setMobileOverlayMode,
  StateSteps,
  toggleActiveDateFilter,
  toggleActiveEventTypeFilter,
  userPosition,
  viewport,
  viewportBounds,
  showBallotProgress,
}) {
  const classes = useStyles();

  const steps = StateSteps ? (
    <StateSteps
      isDesktop={isDesktop}
      className={clsx({
        [classes.mobileStateSteps]: !isDesktop,
        [classes.mobileStateStepsIn]:
          !isDesktop && mobileOverlayMode === 'steps',
        [classes.desktopStateSteps]: isDesktop,
      })}
    />
  ) : null;

  const filterBar = isMinimal ? null : (
    <MapFilters
      className={clsx(classes.filterBar, {
        [classes.mobileFilterBar]: !isDesktop,
      })}
      {...{
        activeDateFilter,
        activeEventTypeFilter,
        eligibleEventTypes,
        eligibleRanges,
        isDesktop,
        mobileOverlayMode,
        setActiveDateFilter,
        setActiveEventTypeFilter,
        setMobileOverlayMode,
        toggleActiveDateFilter,
        toggleActiveEventTypeFilter,
      }}
    />
  );

  const map = (
    <MapInner
      {...{
        activePopup,
        mapRef,
        markers,
        noHover,
        onUpdateBounds,
        showBallotProgress,
        userPosition,
        viewport,
      }}
      className={clsx(classes.map, {
        [classes.mobileMap]: !isDesktop,
        [classes.mobileMapMin]: !isDesktop && isMinimal,
      })}
    />
  );

  const mapList = (
    <div
      className={clsx(classes.mapList, {
        [classes.mapListDesktop]: isDesktop,
        [classes.mapListMobile]: !isDesktop,
        [classes.mapListMobileIn]:
          !isDesktop && mobileOverlayMode === 'mapList',
      })}
    >
      {(isDesktop || mobileOverlayMode === 'mapList') && viewportBounds && (
        <MapList
          bounds={viewportBounds}
          {...{
            activeFilter,
            isDesktop,
            isLoading,
            mapRef,
            markers,
          }}
        />
      )}
    </div>
  );

  const inner = (
    <>
      {isEmbedded && !isMinimal && (
        <Box py={1} className={classes.searchWrap}>
          <SearchWidget className={classes.searchWidget} />
        </Box>
      )}
      {steps}
      {filterBar}
      {mapList}
    </>
  );

  if (isDesktop) {
    return (
      <div className={clsx(classes.wrapper, className)}>
        <div className={classes.desktopSide}>{inner}</div>
        {map}
      </div>
    );
  }

  // mobile

  return (
    <div className={clsx(classes.wrapper, className)}>
      <MapBottomBar
        className={clsx(classes.mapBottomBar)}
        hasSteps={!!steps}
        {...{
          mobileOverlayMode,
          setMobileOverlayMode,
        }}
      />
      {inner}
      {map}
    </div>
  );
}
