import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import HostEventIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import EditYGIcon from '@material-ui/icons/Edit';
import VolunteerIcon from '@material-ui/icons/Group';
import MapIcon from '@material-ui/icons/Room';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import VirtualIcon from '../../components/AntennaIcon';
import { Match } from '@reach/router';
import React from 'react';
import AdapterLink from '../../components/AdapterLink';
import YGEditModal from '../../components/YGEditModal';
import { HOST_EVENT_URL, VOLUNTEER_URL } from '../../config';

const useStyles = makeStyles(theme => ({
  card: {
    maxHeight: 645,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  icon: {
    minWidth: 38,
  },
  drawerHeader: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
  },
}));

export default function SideNav({
  theme,
  open,
  isPermanent,
  handleDrawerClose,
  isDesktop,
  ...otherProps
}) {
  const classes = useStyles();
  const [showYGModal, setShowYGModal] = React.useState(false);

  const navItems = React.useMemo(() => {
    return [
      {
        text: 'Events Map',
        to: '/',
        Icon: MapIcon,
      },
      {
        divider: true,
      },
      {
        text: 'Calendar View',
        to: '/calendar',
        Icon: CalendarTodayIcon,
      },
      {
        divider: true,
      },
      {
        text: 'Virtual Events',
        to: '/virtual',
        Icon: VirtualIcon,
      },
      {
        divider: true,
      },
      {
        text: 'Host an Event',
        href: HOST_EVENT_URL,
        Icon: HostEventIcon,
        component: 'a',
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      {
        divider: true,
      },
      {
        text: 'Volunteer',
        href: VOLUNTEER_URL,
        Icon: VolunteerIcon,
        component: 'a',
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      {
        divider: true,
      },
      {
        text: 'Add/Edit Yang Gang',
        Icon: EditYGIcon,
        onClick: () => {
          setShowYGModal(true);
        },
      },
      {
        divider: true,
      },
    ];
  }, [setShowYGModal]);

  const ListItemLink = ({
    divider,
    text,
    Icon,
    onClick,
    to,
    SubNav,
    ...otherProps
  }) => {
    if (divider) {
      return <Divider />;
    }
    const renderListItem = moreProps => (
      <ListItem
        onClick={onClick || handleDrawerClose}
        button
        key={text}
        {...otherProps}
        {...moreProps}
      >
        <ListItemIcon className={classes.icon}>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
    if (to) {
      return (
        <Match path={to} key={text}>
          {({ match, location }) => {
            const isActive = match && match.uri === to;
            const isChildActive = location.pathname.startsWith(to);
            const listItem = renderListItem({
              selected: isActive,
              to,
              component: AdapterLink,
            });

            if ((!isDesktop || isActive || isChildActive) && SubNav) {
              return (
                <>
                  {listItem}
                  <SubNav handleDrawerClose={handleDrawerClose} />
                </>
              );
            }

            return listItem;
          }}
        </Match>
      );
    }
    return renderListItem({});
  };

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      onClose={handleDrawerClose}
      anchor="left"
      open={open}
      {...otherProps}
    >
      {!isPermanent && (
        <>
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
        </>
      )}
      <Box pb={6}>
        <List disablePadding>
          {navItems.map((props, idx) => (
            <ListItemLink key={props.text || idx} {...props} />
          ))}
        </List>
      </Box>
      <YGEditModal
        open={showYGModal}
        handleClose={() => {
          setShowYGModal(false);
        }}
      />
    </Drawer>
  );
}
